.slideshow-container {
    position: relative;
    width: 100%; /* Adjust width as needed */
    height: 100vh; /* Set height to half of the viewport height */
    overflow: hidden;
    margin-top: 10vh; /* Adjust top margin to leave space for the header */
    border-radius: 40px;
}

.slideshowleft {
    list-style-type: none;
    border-radius: 40px;
    margin-top: auto;
}

    /** SLIDESHOW **/
    .slideshowleft,
    .slideshowleft:after {
        list-style-type: none;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 0;
    }

        .slideshowleft li span {
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0px;
            left: 0px;
            color: transparent;
            background-size: cover;
            background-position: 50% 50%;
            background-repeat: no-repeat;
            opacity: 0;
            z-index: 0;
            animation: imageAnimation 29s linear infinite 0s;
            margin-top: auto;
        }



        .slideshowleft li:nth-child(1) span {
            background-image: url('./slideshow6.png');
         
        }

        .slideshowleft li:nth-child(2) span {
            background-image: url('./slideshow7.png');
            animation-delay: 7s;
        }

        .slideshowleft li:nth-child(3) span {
            background-image: url('./slideshow8.png');
            animation-delay: 13s;
        }

        .slideshowleft li:nth-child(4) span {
            background-image: url('./slideshow9.png');
            animation-delay: 19s;
        }

        .slideshowleft li:nth-child(5) span {
            background-image: url('./slideshow10.png');
            animation-delay: 25s;
        }



@keyframes imageAnimation {
    0% {
        opacity: 0;
        animation-timing-function: ease-in; /* Start with ease-in */
    }

    8% {
        opacity: 1;
        animation-timing-function: ease-out; /* Fades in with ease-out */
    }

    17% {
        opacity: 1; /* Keep visible longer */
        animation-timing-function: ease-out; /* Maintain ease-out */
    }

    30% { /* Delay the fade out */
        opacity: 0; /* Change this percentage to control when it starts fading out */
        animation-timing-function: ease-in; /* Smooth fade out */
    }

    100% {
        opacity: 0;
    }
}


@keyframes titleAnimation {
    0% {
        opacity: 0
    }

    8% {
        opacity: 1
    }

    17% {
        opacity: 1
    }

    19% {
        opacity: 0
    }

    100% {
        opacity: 0
    }
}


.no-cssanimations .cb-slideshowleft li span {
    opacity: 1;
}



@media (max-width: 480px) {
    .slideshow-container {
        height: 45vh; /* Adjust the height for mobile phones */
        margin-top: 4vh; /* Reduce top margin for better view */
        border-radius: 40px;
    }

  
}

@media only screen and (min-width: 559px) and (max-width: 601px) and (min-height: 958px) and (max-height: 965px) {
    .slideshow-container {
        height: 43vh;
    }


}