#bodycss {
    font-family: Arial, sans-serif;
    line-height: 1.6;
    margin: 0;
    padding: 0;
    background-color: #f8e888;
}
#terms-container {
    max-width: 800px;
    margin: 20px auto;
    padding: 20px;
    background-color: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

#terms-header, #terms-subheader {
    color: #333;
}

#terms-header {
    text-align: center;
    margin-bottom: 20px;
}

#terms-subheader {
    margin-top: 20px;
    margin-bottom: 10px;
}

#terms-section {
    margin-bottom: 20px;
}

#terms-list {
    list-style-type: disc;
    margin-left: 20px;
}

#terms-company-name {
    font-weight: bold;
}

#terms-website-url {
    color: #1a73e8;
    text-decoration: none;
}

    #terms-website-url:hover {
        text-decoration: underline;
    }

#terms-paragraph, #terms-list {
    margin-bottom: 10px;
}

#terms-list-item {
    margin-bottom: 5px;
}

@media (max-width: 600px) {
    #terms-container {
        padding: 15px;
    }
}