.book-container {
    font-family: Comic Sans MS, Arial Rounded MT Bold, cursive;
    max-width: 800px;
    margin: 0 auto;
    padding: 30px;
}

#gnerallibraryoptionsheaderstoryform {
    color: #f0f8ff;
    font-size: 35px;
    margin-bottom: 50px;
    line-height: normal;
    text-align: center;
    margin-top: 43px;
}

.loading-font {
    font-family: Comic Sans MS, Arial Rounded MT Bold; /* Use a playful font */
    font-size: 30px;
    margin-bottom: 70px; /* Add some space below the loading message */
    text-align: center; /* Center the text */
    position: relative;
}

.navigation-buttons {
    display: flex; /* Use flexbox */
    justify-content: space-between; /* Align buttons evenly */
}

    .navigation-buttons button {
        width: calc(33.33% - 10px); /* Divide container width equally among buttons */
        margin-right: 10px; /* Add margin between buttons */
        padding: 15px;
        border: none;
        border-radius: 10px;
        background-color: #ff5733; /* Orange button color */
        color: white;
        font-size: 20px;
        cursor: pointer;
        transition: background-color 0.3s ease;
    }

        .navigation-buttons button:hover {
            background-color: #ff834c; /* Darker orange color on hover */
        }



.input-form {
    font-family: Comic Sans MS, Arial Rounded MT Bold, cursive; /* Use a playful font */
    max-width: 500px;
    margin: 0 auto;
    padding: 30px;
    background-color: tomato; /* Light pastel background */
    border-radius: 15px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
    margin-top: 50px;
}

    .input-form label {
        display: block;
        font-size: 24px;
        margin-bottom: 20px;
        color: aliceblue;
 
    }

.input-form select,
.input-form input[type="file"],
.input-form textarea {
    width: 100%;
    padding: 15px;
    margin-bottom: 30px;
    border: 2px solid #f8e888; /* Border color */
    border-radius: 10px;
    font-size: 18px; /* Increase font size for input fields */
    line-height: 1.5; /* Increase line height for readability */
    margin-top: 12px;
    color: #15172b;
}

    .input-form button {
        width: 100%;
        padding: 15px;
        border: none;
        border-radius: 10px;
        background-color: #2c3e50; /* Orange button color */
        color: white;
        font-size: 20px; /* Increase font size for button */
        cursor: pointer;
        transition: background-color 0.3s ease;
    }

        .input-form button:hover {
            background-color: #ff7f50; /* Darker orange on hover */
        }


.success-message {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #4caf50; /* Green background color */
    color: white; /* White text color */
    padding: 15px 30px; /* Padding around the text */
    border-radius: 10px; /* Rounded corners */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Box shadow for a subtle effect */
    z-index: 999; /* Ensure the message appears on top of other content */
    font-size: 16px; /* Font size */
}

    /* Hover effect */
    .success-message:hover {
        background-color: #45a049; /* Darker green on hover */
        cursor: pointer;
    }

    /*loader css*/
.loading {
    font-family: "Arial Black", "Arial Bold", Gadget, sans-serif;
    text-transform: uppercase;
    width: 150px;
    text-align: center;
    line-height: 50px;
    position: absolute;
    left: 0;
    right: 0;
  
    margin: auto;
    transform: translateY(-50%);
}

    .loading span {
        position: relative;
        z-index: 999;
        color: #fff;
    }

    .loading:before {
        content: '';
        background: #61bdb6;
        width: 128px;
        height: 36px;
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto;
        animation: 2s loadingBefore infinite ease-in-out;
    }

@keyframes loadingBefore {
    0% {
        transform: translateX(-14px);
    }

    50% {
        transform: translateX(14px);
    }

    100% {
        transform: translateX(-14px);
    }
}


.loading:after {
    content: '';
    background: #ff3600;
    width: 14px;
    height: 60px;
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    opacity: .5;
    animation: 2s loadingAfter infinite ease-in-out;
}

@keyframes loadingAfter {
    0% {
        transform: translateX(-50px);
    }

    50% {
        transform: translateX(50px);
    }

    100% {
        transform: translateX(-50px);
    }
}



.loader-container {
   
    justify-content: center; /* Horizontally center the loader */
    align-items: center; /* Vertically center the loader */
    min-height: 500px;
    margin-bottom: 50px;
}


#gamecontent {
    margin-top: 70px; /* Add some space above the content */
    width: 100%; /* Make the content take full width */
    text-align: center; /* Center the content text */
}






.book {
    transition: opacity 0.4s 0.2s;
    perspective: 250vw;
    width: 80vw; /* Adjust width of the book */
    height: 44vw; /* Adjust height of the book */
    position: relative;
    transform-style: preserve-3d;
    backface-visibility: hidden;
    border-radius: 4px;
    transform: translateX(-50%);
    left: 50%;
}

/* Page styling */
.page {
    /*   display: block !important; */
    width: 100%; /* Adjust width to fill the whole space of the page */
    height: 100%; /* Adjust height to fill the whole space of the page */
    background-color: #111111;
    margin-bottom: 0.5em;
    background: left top no-repeat;
    background-size: cover;
}

    .page:nth-child(even) {
        clear: both;
    }

/* Content area styling */
.page-content {
    flex: 1;
    padding: 20px; /* Add padding to the content area */
    overflow: hidden; /* Hide overflowing content */
    display: flex;
    align-items: center; /* Center content vertically */
}

    .page-content > div {
        flex: 1;
        padding-right: 20px; /* Add spacing between text and image */
    }

    .page-content img {
        max-width: 100%; /* Adjust image size */
        height: auto; /* Maintain aspect ratio */
    }


/* Book animation and interaction */
.book .page {
    float: none;
    clear: none;
    margin: 0;
    position: absolute;
    top: 0;
    width: 40vw;
    height: 44vw;
    transform-origin: 0 0;
    transition: transform 1.4s;
    backface-visibility: hidden;
    transform-style: preserve-3d;
    cursor: pointer;
    user-select: none;
    background-color: #f0f0f0;
    max-height: 100%; /* Ensure the content fits within the container */
    overflow-y: auto; /* Add vertical scroll for overflowing content */
    word-wrap: break-word; /* Break long words to prevent overflow */
    white-space: normal; /* Allow text to wrap within the container */
}

    .book .page:before {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background: rgba(0, 0, 0, 0);
        transition: background 0.7s;
        z-index: 2;
    }

    .book .page:nth-child(odd) {
        pointer-events: all;
        transform: rotateY(0deg);
        right: 0;
        border-radius: 0 4px 4px 0;
        background-image: linear-gradient(to right, rgba(0, 0, 0, .15) 0%, rgba(0, 0, 0, 0) 10%);
    }

         .book .page:nth-child(odd):hover {
            transform: rotateY(-15deg);
        }

            .book .page:nth-child(odd):hover:before {
                background: rgba(0, 0, 0, 0.03);
            }
      
        .book .page:nth-child(odd):before {
            background: rgba(0, 0, 0, 0);
        }

    .book .page:nth-child(even) {
        pointer-events: none;
        transform: rotateY(180deg);
        transform-origin: 100% 0;
        left: 0;
        border-radius: 4px 0 0 4px;
        border-color: black;
        background-image: linear-gradient(to left, rgba(0, 0, 0, .12) 0%, rgba(0, 0, 0, 0) 10%);
    }

        .book .page:nth-child(even):before {
            background: rgba(0, 0, 0, 0.2);
        }

    .book .page.grabbing {
        transition: none;
    }



  
    .book .page.flipped:nth-child(odd) {
        pointer-events: none;
        transform: rotateY(-180deg);
    }

        .book .page.flipped:nth-child(odd):before {
            background: rgba(0, 0, 0, 0.2);
        }

    .book .page.flipped:nth-child(even) {
        pointer-events: all;
        transform: rotateY(0deg);
    }
       
        .book .page.flipped:nth-child(even):hover {
            transform: rotateY(15deg);
        }
       
            .book .page.flipped:nth-child(even):hover:before {
                background: rgba(0, 0, 0, 0.03);
            }
    
        .book .page.flipped:nth-child(even):before {
            background: rgba(0, 0, 0, 0);
        }


       
/* Global styles */
*,
* :before,
*:after {
    box-sizing: border-box;
}

html,
body {
    font-family: Comic Sans MS, Arial Rounded MT Bold;
    background: #333;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

html {
    height: 100%;
}

body {
    min-height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2em 0;
    line-height: 1.5em;
}

.page:nth-child(odd) {
    background-position: right top;
}



#balance-text {
    position: absolute;
    top: 90px;
    right: 100px;
    background-color: #007bff; /* Blue background */
    color: white; /* White text */
    padding: 10px 20px;
    border-radius: 20px; /* Rounded corners */
    font-size: 1.2em;
    font-weight: bold;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Subtle shadow */
    z-index: 10; /* Ensures the balance text is above other elements */
}

@media (max-width: 480px) {
    #balance-text {
        font-size: 1em;
        padding: 4px 11px;
        right: 33px;
        top: 200px;
        width: 169px;
    }

    .input-form {
        margin-top: 20px;
    }

}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 1) and (orientation: portrait) {

    #balance-text {
        top: 125px!important;
    }
    }


    /*storyformbackground*/

    #storyformbackground {
        background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
        background-size: 400% 400%;
        animation: gradient 15s ease infinite;
        height: 100vh;
    }

    @keyframes gradient {
        0% {
            background-position: 0% 50%;
        }

        50% {
            background-position: 100% 50%;
        }

        100% {
            background-position: 0% 50%;
        }
    }


    /* css for the game while loading*/



input[type="radio"] {
    position: absolute;
    /*  top: -9999em;*/
}


    .center {
        position: absolute;
        top: 40%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    #board {
        width: 50vmin;
        height: 50vmin;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-template-rows: 1fr 1fr 1fr;
        margin-top: 250px;
        position: relative;
    }

    .tile {
        margin: 5%;
        position: relative;
    }

        .tile label,
        .tile div {
            display: block;
            height: 100%;
            width: 100%;
            position: absolute;
            top: 0;
            left: 0;
            background: rgba(0,0,0,0.10);
            font-family: Raleway, Courier, 'Courier New', Sans, sans-serif;
            font-weight: bolder;
        }

        .tile div {
            display: none;
            overflow: hidden;
            text-shadow: 0 1px 6px rgba(0,0,0,0.85)
        }

    label[for$='-x'] {
        z-index: 1;
    }

    input:checked ~ #board label[for$='-o'] {
        z-index: 2;
    }

    input:checked ~ input:checked ~ #board label[for$='-x'] {
        z-index: 3;
    }

    input:checked ~ input:checked ~ input:checked ~ #board label[for$='-o'] {
        z-index: 4;
    }

    input:checked ~ input:checked ~ input:checked ~ input:checked ~ #board label[for$='-x'] {
        z-index: 5;
    }

    input:checked ~ input:checked ~ input:checked ~ input:checked ~ input:checked ~ #board label[for$='-o'] {
        z-index: 6;
    }

    input:checked ~ input:checked ~ input:checked ~ input:checked ~ input:checked ~ input:checked ~ #board label[for$='-x'] {
        z-index: 7;
    }

    input:checked ~ input:checked ~ input:checked ~ input:checked ~ input:checked ~ input:checked ~ input:checked ~ #board label[for$='-o'] {
        z-index: 8;
    }

    input:checked ~ input:checked ~ input:checked ~ input:checked ~ input:checked ~ input:checked ~ input:checked ~ input:checked ~ #board label[for$='-x'] {
        z-index: 9;
    }

    input[id*='-0-']:checked ~ #board label[for*='-0-'],
    input[id*='-1-']:checked ~ #board label[for*='-1-'],
    input[id*='-2-']:checked ~ #board label[for*='-2-'],
    input[id*='-3-']:checked ~ #board label[for*='-3-'],
    input[id*='-4-']:checked ~ #board label[for*='-4-'],
    input[id*='-5-']:checked ~ #board label[for*='-5-'],
    input[id*='-6-']:checked ~ #board label[for*='-6-'],
    input[id*='-7-']:checked ~ #board label[for*='-7-'],
    input[id*='-8-']:checked ~ #board label[for*='-8-'] {
        display: none;
    }

    input[id*='-0-']:checked ~ #board #tile-0 div,
    input[id*='-1-']:checked ~ #board #tile-1 div,
    input[id*='-2-']:checked ~ #board #tile-2 div,
    input[id*='-3-']:checked ~ #board #tile-3 div,
    input[id*='-4-']:checked ~ #board #tile-4 div,
    input[id*='-5-']:checked ~ #board #tile-5 div,
    input[id*='-6-']:checked ~ #board #tile-6 div,
    input[id*='-7-']:checked ~ #board #tile-7 div,
    input[id*='-8-']:checked ~ #board #tile-8 div {
        display: block;
    }

    input[id*='-0-x']:checked ~ #board #tile-0 div::before,
    input[id*='-1-x']:checked ~ #board #tile-1 div::before,
    input[id*='-2-x']:checked ~ #board #tile-2 div::before,
    input[id*='-3-x']:checked ~ #board #tile-3 div::before,
    input[id*='-4-x']:checked ~ #board #tile-4 div::before,
    input[id*='-5-x']:checked ~ #board #tile-5 div::before,
    input[id*='-6-x']:checked ~ #board #tile-6 div::before,
    input[id*='-7-x']:checked ~ #board #tile-7 div::before,
    input[id*='-8-x']:checked ~ #board #tile-8 div::before {
        content: "X";
        background: #004974;
        color: #89dcf6;
    }

    input[id*='-0-o']:checked ~ #board #tile-0 div::before,
    input[id*='-1-o']:checked ~ #board #tile-1 div::before,
    input[id*='-2-o']:checked ~ #board #tile-2 div::before,
    input[id*='-3-o']:checked ~ #board #tile-3 div::before,
    input[id*='-4-o']:checked ~ #board #tile-4 div::before,
    input[id*='-5-o']:checked ~ #board #tile-5 div::before,
    input[id*='-6-o']:checked ~ #board #tile-6 div::before,
    input[id*='-7-o']:checked ~ #board #tile-7 div::before,
    input[id*='-8-o']:checked ~ #board #tile-8 div::before {
        content: "O";
        background: #a60011;
        color: #ffc7b5;
    }

    .tile label:hover {
        cursor: pointer;
        background: rgba(0,0,0,0.25);
    }

    .tile label::before,
    .tile div::before {
        color: #000;
        position: absolute;
        text-align: center;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-size: 7.5vmin;
        font-weight: bold;
    }

    .tile div::before {
        padding: 100%;
    }

    .tile label[for$='-o']:hover::before {
        content: "O";
    }

    .tile label[for$='-x']:hover::before {
        content: "X";
    }

    #tile-0 {
        grid-column: 1;
        grid-row: 1;
    }

        #tile-0 label,
        #tile-0 div {
            border-radius: 10% 0 0 0;
        }

    #tile-1 {
        grid-column: 2;
        grid-row: 1;
    }

    #tile-2 {
        grid-column: 3;
        grid-row: 1;
    }

        #tile-2 label,
        #tile-2 div {
            border-radius: 0 10% 0 0;
        }

    #tile-3 {
        grid-column: 1;
        grid-row: 2;
    }

    #tile-4 {
        grid-column: 2;
        grid-row: 2;
    }

    #tile-5 {
        grid-column: 3;
        grid-row: 2;
    }

    #tile-6 {
        grid-column: 1;
        grid-row: 3;
    }

        #tile-6 label,
        #tile-6 div {
            border-radius: 0 0 0 10%;
        }

    #tile-7 {
        grid-column: 2;
        grid-row: 3;
    }

    #tile-8 {
        grid-column: 3;
        grid-row: 3;
    }

        #tile-8 label,
        #tile-8 div {
            border-radius: 0 0 10% 0;
        }

    #end {
        background: rgba(255,255,255,0.85);
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        display: none;
    }

    #message {
        text-align: center;
        font-size: 2rem;
        line-height: 2.2rem;
        font-weight: bold;
    }

        #message::before {
            content: "Tied game!"
        }

        #message input {
            background: #000;
            color: #fff;
            font-size: 1rem;
            padding: 0.5rem 1.75rem;
            margin: auto auto;
            margin-top: 2rem;
        }

    input:checked ~ input:checked ~ input:checked ~ input:checked ~ input:checked ~ input:checked ~ input:checked ~ input:checked ~ input:checked ~ #end,
    #cell-0-x:checked ~ #cell-1-x:checked ~ #cell-2-x:checked ~ #end,
    #cell-3-x:checked ~ #cell-4-x:checked ~ #cell-5-x:checked ~ #end,
    #cell-6-x:checked ~ #cell-7-x:checked ~ #cell-8-x:checked ~ #end,
    #cell-0-x:checked ~ #cell-3-x:checked ~ #cell-6-x:checked ~ #end,
    #cell-1-x:checked ~ #cell-4-x:checked ~ #cell-7-x:checked ~ #end,
    #cell-2-x:checked ~ #cell-5-x:checked ~ #cell-8-x:checked ~ #end,
    #cell-0-x:checked ~ #cell-4-x:checked ~ #cell-8-x:checked ~ #end,
    #cell-2-x:checked ~ #cell-4-x:checked ~ #cell-6-x:checked ~ #end,
    #cell-0-o:checked ~ #cell-1-o:checked ~ #cell-2-o:checked ~ #end,
    #cell-3-o:checked ~ #cell-4-o:checked ~ #cell-5-o:checked ~ #end,
    #cell-6-o:checked ~ #cell-7-o:checked ~ #cell-8-o:checked ~ #end,
    #cell-0-o:checked ~ #cell-3-o:checked ~ #cell-6-o:checked ~ #end,
    #cell-1-o:checked ~ #cell-4-o:checked ~ #cell-7-o:checked ~ #end,
    #cell-2-o:checked ~ #cell-5-o:checked ~ #cell-8-o:checked ~ #end,
    #cell-0-o:checked ~ #cell-4-o:checked ~ #cell-8-o:checked ~ #end,
    #cell-2-o:checked ~ #cell-4-o:checked ~ #cell-6-o:checked ~ #end {
        display: block;
    }

        #cell-0-x:checked ~ #cell-1-x:checked ~ #cell-2-x:checked ~ #end #message::before,
        #cell-3-x:checked ~ #cell-4-x:checked ~ #cell-5-x:checked ~ #end #message::before,
        #cell-6-x:checked ~ #cell-7-x:checked ~ #cell-8-x:checked ~ #end #message::before,
        #cell-0-x:checked ~ #cell-3-x:checked ~ #cell-6-x:checked ~ #end #message::before,
        #cell-1-x:checked ~ #cell-4-x:checked ~ #cell-7-x:checked ~ #end #message::before,
        #cell-2-x:checked ~ #cell-5-x:checked ~ #cell-8-x:checked ~ #end #message::before,
        #cell-0-x:checked ~ #cell-4-x:checked ~ #cell-8-x:checked ~ #end #message::before,
        #cell-2-x:checked ~ #cell-4-x:checked ~ #cell-6-x:checked ~ #end #message::before {
            content: "Player 1 won!";
        }

        #cell-0-o:checked ~ #cell-1-o:checked ~ #cell-2-o:checked ~ #end #message::before,
        #cell-3-o:checked ~ #cell-4-o:checked ~ #cell-5-o:checked ~ #end #message::before,
        #cell-6-o:checked ~ #cell-7-o:checked ~ #cell-8-o:checked ~ #end #message::before,
        #cell-0-o:checked ~ #cell-3-o:checked ~ #cell-6-o:checked ~ #end #message::before,
        #cell-1-o:checked ~ #cell-4-o:checked ~ #cell-7-o:checked ~ #end #message::before,
        #cell-2-o:checked ~ #cell-5-o:checked ~ #cell-8-o:checked ~ #end #message::before,
        #cell-0-o:checked ~ #cell-4-o:checked ~ #cell-8-o:checked ~ #end #message::before,
        #cell-2-o:checked ~ #cell-4-o:checked ~ #cell-6-o:checked ~ #end #message::before {
            content: "Player 2 won!";
        }

    /*background for book and pages*/
    #bookbackground {
        background-image: url('./tablepic.png');
        background-repeat: no-repeat; /* Ensure the image does not repeat */
        background-position: center; /* Center the image */
        background-size: cover;
        margin-top: 10px;
        margin-bottom: 10px;
    }

    .frontpage-background {
        background-image: url('./frontbookcover.png') !important;
        background-repeat: no-repeat;
        background-position: center;
        background-size: 800px 730px;
        background-position-x: -110px !important;
        background-position-y: -70px !important;
    }

    .backpage-background {
        background-image: url('./backbookcover.png') !important;
        background-repeat: no-repeat;
        background-position: center;
        background-size: 780px 740px;
        background-position-x: -140px !important;
        background-position-y: -55px !important;
    }

    #messageForFlipPhones {
        display: none;
    }

@media (max-width: 800px) {

    .page {
        display: block !important;
    }

}

    @media (max-width: 480px) {
        .book {
            width: 104vw; /* Use full width on mobile */
            height: 74vw; /* Adjust height accordingly */
        }

            .book .page:nth-child(2n+1) {
                right: 18px;
            }

        .page:nth-child(even) {
            left: 12px !important;
        }

        #librarynavigation-buttons {
            margin-top: 5px;
            margin-bottom: 5px;
        }

        #booktitlestyle {
            font-size: 1.3em;
        }

        #librarynavigation-buttons button {
            padding: 8px;
            font-size: 14px;
        }

        .page {
            display: block;
        }

        .book .page {
            width: 48.5vw;
            height: 72vw;
            max-height: 100%; /* Ensure the content fits within the container */
            overflow-y: auto; /* Add vertical scroll for overflowing content */
            word-wrap: break-word; /* Break long words to prevent overflow */
            white-space: normal; /* Allow text to wrap within the container */
            padding: 15px !important;
        }

        .page-content {
            padding: 7px;
        }

        .page-content-left {
            white-space: normal; /* Allow text to wrap within the container */
            padding: 10px !important;
            font-size: x-small;
        }

        .page-content img {
            margin-top: 30%;
            max-width: 104%;
        }

        .frontpage-background {
            background-position-x: -38px !important;
            background-position-y: -30px !important;
            background-size: 295px 365px;
        }

        .backpage-background {
            background-position-x: -54px !important;
            background-position-y: -28px !important;
            background-repeat: no-repeat;
            background-size: 300px 366px;
        }


        #messageForFlipPhones {
            display: block;
            position: fixed;
            left: 50%;
            transform: translateX(-50%);
            background-color: #fff;
            border: 1px solid #ccc;
            border-radius: 5px;
            padding: 10px;
            box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
            z-index: 9999;
            width: 75%;
        }

            #messageForFlipPhones p {
                margin: 0;
                padding-right: 30px; /* To create space for the close button */
            }

        .close-button {
            position: absolute;
            top: 5px;
            right: 5px;
            background: none;
            border: none;
            font-size: 16px;
            cursor: pointer;
            color: #888;
        }

        #libraryforminsidediv {
            padding: 80px;
            height: 60vh;
        }
    }


    #notloggedinadjusterforstoryform {
        margin: 256px auto !important;
    }