#AppContainer {
    max-width: 1400px; /* Adjust as needed */
    margin: 0 auto; /* Center the content horizontally */
    padding: 0 20px; /* Optional: Add some padding */
}

@media screen and (max-width:810px) {
    #AppContainer {
        margin: 30px auto;
    }
}



    #gwowhomepageadjuster {
     
        padding: 0;
    }



.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #d9b99b;
    min-height: 10vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: rebeccapurple;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

.navigation-buttons {
    display: flex; /* Use flexbox */
    justify-content: space-between; /* Align buttons evenly */
}

    .navigation-buttons button {
        width: calc(33.33% - 10px); /* Divide container width equally among buttons */
        margin-right: 10px; /* Add margin between buttons */
        padding: 15px;
        border: none;
        border-radius: 10px;
        background-color: #ff5733; /* Orange button color */
        color: white;
        font-size: 20px;
        cursor: pointer;
        transition: background-color 0.3s ease;
    }

        .navigation-buttons button:hover {
            background-color: #ff834c; /* Darker orange color on hover */
        }





@media screen and (max-width: 375px) {
    /* For iPhone 12/13 mini */
    .parent-container {
        margin-top: 43px !important;
    }
}
@media screen and (max-width: 385px) {
   
    .parent-container {
        margin-top: 9px;
    }
}

@media screen and (max-width: 415px) {
    .parent-container {
        margin-top: 75px !important;
        height: 34vh;
        width: 102%
    }
}
@media screen and (max-width: 760px) {
    .parent-container {
        margin-top: 8px;
    }
}

.parent-container {
    display: flex; /* Use flexbox */
    margin: auto;
    width: 98%;
    
    margin-bottom: 3px;
}









    .slideshow-container {
        position: relative; /* Set the container position to relative */
        width: 50%; /* Adjust as needed */
        /* height: 100%; Adjust as needed */
        overflow: hidden; /* Hide any overflow */
    }







.hero {
    display: grid;
    justify-content: center;
    align-items: center;
    min-height: 34vh;
}



#textheadline {
    position: relative;
    font-size: 3.5em;
    font-weight: 700;
    color: #f5f5f5;
    text-shadow: 1px 1px 2px #919191, 2px 2px 3px #919191, 3px 3px 4px rgba(16, 16, 16, 0.3), 4px 4px 5px rgba(16, 16, 16, 0.2), 5px 5px 10px rgba(16, 16, 16, 0.15);
    text-align: center;
    line-height: initial;
    font-family: Comic Sans MS, Arial Rounded MT Bold;
    margin-top: 15px;
}

#textheadlinesecond {
    position: relative;
    font-size: 8.5em;
    font-weight: 700;
    color: #f5f5f5;
    text-shadow: 1px 4px 1px #919191,1px 5px 1px #919191,1px 6px 1px #919191,1px 18px 6px #10101066,1px 22px 10px #10101033,1px 25px 35px #10101033,1px 30px 60px #10101066; text-align: center;
    margin-bottom: 125px;
    font-family: Comic Sans MS, Arial Rounded MT Bold;
    margin-top: 85px;
}

    /* Responsive styling */

@media screen and (max-width: 411px) {
    
    .hero {
    display: inherit;
    margin-bottom: -207px;
}
}
    @media screen and (max-width: 480px) {
        #textheadline {
            margin-top: 10px;
            text-align: center;
            font-size: 1.3em;
            font-family: Comic Sans MS, Arial Rounded MT Bold;
            text-shadow: 1px 1px 2px #919191, 2px 2px 3px #919191, 3px 3px 4px rgba(16, 16, 16, 0.3), 4px 4px 5px rgba(16, 16, 16, 0.2), 5px 5px 10px rgba(16, 16, 16, 0.15); /* Enhanced shadow layers */
        }

        #textheadlinesecond {
            margin-top: 10px;
            text-align: center;
            font-size: 1.6em;
            font-family: Comic Sans MS, Arial Rounded MT Bold;
            text-shadow: 1px 1px 2px #919191, 2px 2px 3px #919191, 3px 3px 4px rgba(16, 16, 16, 0.3), 4px 4px 5px rgba(16, 16, 16, 0.2), 5px 5px 10px rgba(16, 16, 16, 0.15); /* Enhanced shadow layers */
        }

        .hero {
            display: inherit;
            margin-bottom: -222px;
        }
    }


    @media (max-width: 410px) {
        #textheadlinesecond {
            font-size: 55px !important;
            left: 8% !important;
        }
    }

    @media (max-width: 768px) {
        #textheadline {
            font-size: 19px;
            margin-top: 24px;
            font-size: 1.3em;
            font-family: Comic Sans MS, Arial Rounded MT Bold;
        }

        #textheadlinesecond {
            margin-top: 30px;
            text-align: center;
            font-size: 4.5em;
            font-family: Comic Sans MS, Arial Rounded MT Bold;
            width: max-content;
        }

        .hero {
            display: inherit;
            margin-bottom: -200px;
            font-family: Comic Sans MS, Arial Rounded MT Bold;
        }
    }
@media (max-width: 395px) {
    #textheadlinesecond {
       left:10%;
    }
}

    #videostyle {
        margin-top: 35px;
    }


.subnav {
    background: #ffaf45;
    /*border-bottom: 1px solid #df1212; */
    position: sticky;
    top: 0; /* Ensure it sticks to the top */
   
    border-radius: 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 15px; /* Add padding for spacing */
    z-index: 1000; /* Ensure it stays on top */
    max-height: 80px;
}

        .subnav .container {
            display: flex;
            flex-wrap: wrap; /* Allow items to wrap */
            align-items: center;
            justify-content: space-between;
            width: 100%;
        }

        .subnav .title {
            font-size: 1.25em;
            color: coral; /* Applied color to the title */
            margin: 0;
        }

        .subnav .list {
            display: flex;
            align-items: center;
            flex-wrap: wrap; /* Allow items to wrap */
            margin: 0;
            padding: 0;
            list-style: none; /* Remove default list styling */
        }

        .subnav .item {
            display: inline-block;
            margin: 0 8px;
            padding: 5px;
        }
@media (max-width: 750px) {
    #subnavadjuster {
        margin-bottom: -19px;
    }
}
    @media (max-width: 425px) {
        /* Add a top margin to the subnav to create space below the gwowhomepageadjuster */
        #gwowhomepageadjuster + .subnav {
            margin-top: 150px;
            line-height: 1.2em;
        }
    }

    @media (min-width: 425px) (max-width: 650px) {
        /* Add a top margin to the subnav to create space below the gwowhomepageadjuster */
        #gwowhomepageadjuster + .subnav {
            margin-top: 12px; /* Adjust this value as needed */
        }
    }


    /* Responsive adjustments */
    @media (min-width: 600px)(max-width: 768px) {



        .subnav {
            display: none;
        }
    }
    /*
            flex-direction: column;
            height: 10%;
            padding: 10px;
        }

            .subnav .container {
                flex-direction: column;
                align-items: flex-start;
            }

            .subnav .list {
                width: 100%;
                justify-content: center;
                flex-wrap: initial;
                font-size: 15px;
                margin-top: -25px;
            }

            .subnav .item {
                margin: 5px 0;
            }

            .subnav .title {
                font-size: 1.1em;
                font-family: Comic Sans MS, Arial Rounded MT Bold;
            }


        .form {
            width: 100% !important;
            height: auto !important;
        }

        .loginform {
            width: 100% !important;
            height: auto !important;
        }
    }*/

    @media (max-width: 480px) {
        .subnav .title {
            font-size: 1em;
            font-family: Comic Sans MS, Arial Rounded MT Bold;
        }

        .subnav .item {
            margin: 3px 0;
            font-size: 13px;
        }

        #subnavtitle {
            margin-top: 0px;
            font-size: 25px;
        }
    }

@media (max-width: 370px) {
    #subnavtitle {
        font-size: 16px;
    }

    
}
.terms-link {
    color: #08d;
    text-decoration: underline;
}

    .section {
        max-width: 900px;
        margin: 60px auto;
        padding: 90px 30px;
        background-color: tomato;
        text-align: center;
        border-radius: 30px;
    }

        .section.custom-margin {
            margin: 130px auto;
        }

        .section.custom-color-for-managesub {
            background-color: unset;
            padding: 127px 34px;
        }

    #subscriptiondetails {
        background-color: tomato;
        height: 65vh;
        border-radius: 45px;
        display: grid;
        margin-top: -107px;
    }



    @media (max-width: 1200px) and (max-height: 450px) {
        #subscriptiondetails {
            height: 110vh !important;
        }
    }



    #subscriptionupgrade {
        background-color: #15172b;
        margin-top: 78px;
        border-radius: 30px;
    }

    #submanage__upgrade-text {
        font-size: 25px;
        padding-top: 54px;
        padding-bottom: 30px;
    }

    #toggleButton {
    }

    #submanage__info_1 {
        font-size: 33px;
        margin-left: 27px;
        padding-top: 32px;
    }

    #submanage__info_1_1 {
        font-size: 25px;
    }

    #submanage__info_2 {
        font-size: 19px;
        margin-left: 95px;
        margin-top: 14px;
    }

    #submanage__info_2_2 {
        font-size: 25px;
        margin-left: 25px;
        margin-top: 14px;
        color: #15172B;
    }

    #submanage__info_3 {
        font-size: 19px;
        margin-left: 95px;
        margin-top: 14px;
    }

    #submanage__info_3_3 {
        font-size: 25px;
        margin-left: 22px;
        margin-top: 14px;
        color: #15172B;
    }

    #strongvariables {
    }


    /* Styling for the Renew Subscription button */
    #renewSubButton {
        padding: 12px 40px;
        border-radius: 30px;
        font-size: 16px;
        font-weight: bold;
        text-transform: uppercase;
        letter-spacing: 1px;
        cursor: pointer;
        background-color: #27AE60; /* A pleasant green color to signal a positive action */
        color: white;
        border: none;
        box-shadow: 0 8px 15px rgba(39, 174, 96, 0.4);
        margin-top: 15px; /* Ensure spacing */
        width: 80%;
        max-width: 200px; /* Limit button width to ensure it doesn't get too large */
    }

        #renewSubButton:hover {
            background-color: #2ECC71; /* Slightly lighter green on hover for visual feedback */
            box-shadow: 0 12px 20px rgba(39, 174, 96, 0.6);
            transform: translateY(-3px);
        }

    /* Styling for the Cancel Subscription button */
    #cancelSubButton {
        padding: 15px 40px;
        border-radius: 30px;
        font-size: 16px;
        font-weight: bold;
        text-transform: uppercase;
        letter-spacing: 1px;
        cursor: pointer;
        background-color: #007BFF; /* New vibrant blue color */
        color: white;
        border: none;
        box-shadow: 0 8px 15px #007BFF66; /* Light blue shadow */
        margin-top: 15px; /* Ensure spacing */
        width: 80%;
        max-width: 200px; /* Limit button width to ensure it doesn't get too large */
        margin-left: 90px;
    }

        #cancelSubButton:hover {
            background-color: #66B2FF; /* Slightly lighter blue on hover */
            box-shadow: 0 12px 20px rgba(0, 123, 255, 0.6); /* Lighter blue shadow */
            transform: translateY(-3px);
        }

    #cancelrenewoptions {
        padding-top: 5px;
    }


    @media (max-width: 480px) {

        #cancelrenewoptions {
            margin-left: -13%;
            scale: 76%;
        }


        #renewSubButton {
            max-width: 189px;
            padding: 11px 30px;
        }


        #cancelSubButton {
            margin-left: 0px;
            max-width: 200px;
            padding: 11px 30px;
        }
    }



    @media (max-width: 376px) {
        #submanage__info_1 {
            margin-bottom: 3px;
        }
    }

    @media (max-width: 400px) {
        #cancelrenewoptions {
            margin-left: -13%;
        }





        #cancelSubButton {
            margin-left: 0px;
        }
    }

    @media (max-width: 480px) {



        #submanage__upgrade-text {
            font-size: 20px;
            padding-top: 54px;
        }


        #submanage__info_1 {
            font-size: 19px;
            margin-left: -9px;
            padding-top: 50px;
            margin-bottom: -34px;
        }

        #submanage__info_1_1 {
            font-size: 25px;
        }

        #submanage__info_2 {
            font-size: 16px;
            margin-left: 0; /* Remove left margin */
            margin-top: 7px; /* Adjust top margin */
            text-align: left; /* Align text to the left */
            display: block; /* Ensure it starts on a new line */
            margin-bottom: -15px;
        }

        #submanage__info_2_2 {
            font-size: 20px;
            margin-left: 0; /* Remove left margin */
            margin-top: 10px; /* Space between the two elements */
            color: #15172B;
            text-align: left; /* Align text to the left */
            display: block;
        }

        /* For #submanage__info_3 and #submanage__info_3_3 */
        #submanage__info_3 {
            font-size: 16px;
            margin-left: 0; /* Remove left margin */
            margin-top: 0px;
            text-align: left; /* Align text to the left */
            display: block;
        }

        #submanage__info_3_3 {
            font-size: 20px;
            margin-left: 0; /* Remove left margin */
            margin-top: 10px;
            color: #15172B;
            text-align: left; /* Align text to the left */
            display: block;
        }


        #subscriptiondetails {
            padding-left: 32px;
        }
    }

    @media (max-width: 385px) {
        #submanage__info_1 {
            margin-left: -22px;
            margin-bottom: 2px;
        }

        #submanage__toggle-container_header {
            scale: 19% !important;
        }

        #submanage__info_3_3 {
            font-size: 17px;
        }

        #submanage__info_2_2 {
            font-size: 16px;
        }

        .pricing-table .content ul {
            margin-bottom: 50px;
        }
    }



    /* Overlay that covers the entire screen */
    #confirmation-overlay {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.6); /* Semi-transparent black */
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 1000; /* Ensures it stays above all other elements */
    }

    /* Popup window styling */
    #confirmation-popup {
        background-color: #fff2a3;
        padding: 20px;
        border-radius: 8px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
        max-width: 400px;
        width: 100%;
        text-align: center;
        z-index: 1001; /* Ensures it's above the overlay */
        color: #121920;
    }

    #confirmation-popupfont {
        color: #121920;
    }
    /* Button styling */
    #popup-buttons {
        display: flex;
        justify-content: space-around;
        margin-top: 20px;
    }

        #popup-buttons button {
            padding: 10px 30px;
            border: none;
            border-radius: 4px;
            cursor: pointer;
            background-color: #007BFF;
            color: white;
            font-size: 14px;
        }

            #popup-buttons button:hover {
                background-color: #0056b3;
            }

            /* Optional: Customize specific button colors */
            #popup-buttons button:nth-child(1) {
                background-color: #dc3545; /* Red for 'Yes' */
            }

                #popup-buttons button:nth-child(1):hover {
                    background-color: #c82333;
                }













    /* Wrapper for the toggle button */
    .wrappersubpage {
        position: relative; /* Changed to relative for positioning inside the page */
        display: inline-block; /* Adjust to fit inside your button container */
        height: 100px; /* Adjust height */
        width: 200px; /* Adjust width */
        margin-top: 10px; /* Add spacing around the toggle if needed */
    }

        /* Toggle Button Input (Hidden but interactive) */
        .wrappersubpage input[type="checkbox"] {
            appearance: none;
            -webkit-appearance: none;
            position: absolute;
            transform: translate(-50%, -50%);
            top: 50%;
            left: 50%;
            height: 100px;
            width: 200px;
            background-color: #f2f2f2;
            border-radius: 100px;
            cursor: pointer;
            transition: 0.5s;
        }

    /* Toggle Circle (Label) */
    label[for="toggleButton"] {
        position: absolute;
        background-color: #cccccc;
        margin: auto;
        top: 0;
        bottom: 0;
        left: 5px; /* Adjust to fit label inside */
        height: 100px;
        width: 100px;
        border: 10px solid #e7e7e7;
        border-radius: 50%;
        box-shadow: inset -6px 6px rgba(0, 0, 0, 0.2);
        cursor: pointer;
        transition: 0.5s;
    }

        /* Label Inner Circle (Before pseudo element) */
        label[for="toggleButton"]:before {
            content: "";
            position: absolute;
            height: 10px;
            width: 30px;
            background-image: linear-gradient(to left, #bebebe 30%, #cccccc 30%);
            margin: auto;
            top: 0;
            bottom: 0;
            left: -38px;
        }

    /* Checkbox Checked State (Active background color) */
    .wrappersubpage input[type="checkbox"]:checked {
        background-color: #ff6347;
    }

        /* Move and Change the Circle on Checked State */
        .wrappersubpage input[type="checkbox"]:checked + label[for="toggleButton"] {
            transform: translateX(100px) rotate(180deg); /* Adjust for full movement */
            background-color: #6e4c42;
            box-shadow: inset 6px -6px rgba(0, 0, 0, 0.2);
        }


    #submanage__upgrade-text {
        top: 50%;
        left: 50%;
    }

    #submanage__toggle-container_header {
        scale: 50%;
        margin-left: -7%;
    }

    @media (max-width: 480px) {
        #submanage__toggle-container_header {
            scale: 25%;
            margin-left: -44%;
            margin-top: -12%;
        }
    }


    #submanage__toggle-container {
        width: max-content;
    }


    #subscriptionmonthlyleft {
        color: #f8e888;
        font-size: 100px;
        padding-right: 50px;
    }

    #subscriptionmonthlyleftclicked {
        color: #ff6347;
        font-size: 100px;
        padding-right: 50px;
    }

    #subscriptionanuallyright {
        color: #f8e888;
        font-size: 100px;
    }

    #subscriptionanuallyrightclicked {
        color: #ff6347;
        font-size: 100px;
    }










    .section h2 {
        font-size: 3em;
        margin-bottom: 15px;
        margin-top: auto;
        color: #2c3e50;
        line-height: 1.2;
    }

    .section h3 {
        font-size: 2em;
        margin-bottom: 15px;
    }

    .section p {
        line-height: 1.625em;
        margin: 0 auto;
        color: moccasin;
        margin-bottom: inherit;
    }

    .section #contentbookselectionheader {
        color: moccasin;
        font-size: xx-large;
    }

    @media (max-width: 480px) {
        .section #contentbookselectionheader {
            color: moccasin;
            font-size: 28px;
            line-height: normal;
        }

        #gnerallibraryoptionsheaderstoryform {
        }
    }

    /* Styles for tablet devices like iPads (between 768px and 1024px) */
    @media (max-width: 1024px) {
        .section #contentbookselectionheader {
            color: moccasin;
            font-size: 24px !important; /* Adjust for tablet */
            line-height: normal;
            background-color: #00000080; /* Semi-transparent background */
            border-radius: 10px;
            left: 50%;
            padding: 10px;
            position: absolute;
            transform: translateX(-50%); /* Center horizontally */
        }
    }

    .section #contentbookselectiontext {
        color: moccasin;
        font-size: xx-large;
    }

    .section #contentbookselectiontextipad {
        display: none; /* Hidden by default on non-iPad devices */
    }

    @media (max-width: 480px) {
        .section #contentbookselectiontext {
            color: moccasin;
            font-size: 28px;
            line-height: normal;
        }

        .section #contentbookselectiontextipad {
            display: none; /* Hidden on mobile */
        }
    }
    /* Styles for tablet devices (iPads specifically, between 768px and 1024px) */
    @media screen and (min-width: 768px) and (max-width: 1200px) {
        .section #contentbookselectiontextipad {
            background-color: #000000ab;
            border-radius: 10px;
            color: #ffe4b5;
            display: block !important;
            font-size: 16px !important;
            left: 50%;
            line-height: normal;
            position: absolute;
            top: 93%;
            transform: translateX(-50%);
            width: 88%;
            padding-left: 5px;
            padding-right: 5px;
            padding-top: 10px;
            padding-bottom: 10px;
        }

        .section #contentbookselectiontext {
            display: none; /* Hide the default text on iPads */
        }

        #containergwow {
            flex-wrap: wrap !important;
        }

        .slideshow-container {
            height: 46vh;
        }
    }

    /* Desktop and larger screens */
    @media (min-width: 1025px) {
        .section #contentbookselectiontext {
            display: block; /* Visible on desktop and larger screens */
        }

        .section #contentbookselectiontextipad {
            display: none; /* Hidden on desktop */
        }
    }






    /* for the FAQ*/
    .section h4 {
        font-size: 1.8em;
        margin-bottom: 15px;
        cursor: pointer; /* Add cursor pointer to indicate clickability */
        color: moccasin;
        transition: color 0.3s ease; /* Add transition for color change */
        background-color: cornflowerblue;
        border-radius: 25px;
        line-height: normal;
        text-align: center;
        padding: 8px;
    }

        .section h4:hover {
            color: #ffebcd; /* Change color on hover */
        }

    .section p1 {
        line-height: 1.625em;
        margin: 0 auto;
        color: moccasin;
        margin-bottom: inherit;
        display: none; /* Hide answers by default */
    }

    .section div p1 {
        display: block; /* Show answer when parent div is open */
    }

    @media (max-width: 480px) {
        .section h4 {
            font-size: 1.5em;
            font-family: Comic Sans MS, Arial Rounded MT Bold;
        }

        .section h2 {
            font-size: 2em;
            font-family: Comic Sans MS, Arial Rounded MT Bold;
        }
    }





    /*css for the cards*/
    .cardcontainer {
        display: flex; /* Use flexbox */
    }

    #cardheader {
        color: #2c3e50;
    }



    .card {
        border-radius: 16px;
        margin: 5px;
        width: 33%;
        max-width: 33%;
        min-height: 200px;
        box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
        overflow: hidden;
        background-size: cover;
    }

    /* Background image classes */
    .cardone {
        background-image: url('./ignitecreativity.png');
        background-position: center;
    }

    .cardtwo {
        background-image: url('./connectdeeperlevel.png');
        background-position: center;
    }

    .cardthree {
        background-image: url('./funlearnningadventures.png');
        background-position: center;
    }


    .info {
        position: relative;
        width: 100%;
        height: 300px;
        background-color: #fff;
        transform: translateY(100%) translateY(-8px) translateZ(0);
        transition: transform 0.5s ease-out;
    }

        .info:before {
            z-index: -1;
            display: block;
            position: absolute;
            content: ' ';
            width: 100%;
            height: 100%;
            overflow: hidden;
            filter: blur(10px);
            background-size: cover;
            opacity: 0.25;
            transform: translateY(-100%) translateY(88px) translateZ(0);
            transition: transform 0.5s ease-out;
        }

    .card:hover .info,
    .card:hover .info:before {
        transform: translateY(0) translateZ(0);
    }

    .title {
        margin: 0;
        padding: 24px;
        font-size: 16px;
        line-height: 1.5;
        color: rgba(0, 0, 0, 0.87);
        text-align: center;
    }

    .description {
        margin: 0;
        padding: 0 24px 24px;
        font-size: 13px;
        line-height: 1.5;
        color: #2c3e50 !important;
    }

    /* Keyframes for alternating image and description */
    @keyframes alternateContent {
        0%, 100% {
            opacity: 1;
            visibility: visible; /* Element is fully visible */
        }
    }

    @keyframes alternateDescription {
        0%, 10% {
            opacity: 1; /* Fully visible at start */
            visibility: visible; /* Visible at the start */
        }

        20% {
            opacity: 0; /* Start fading out */
            visibility: visible; /* Keep visible until faded out */
        }

        30% {
            opacity: 0; /* Fully faded out */
            visibility: hidden; /* Hidden after fade out is complete */
        }

        40%, 100% {
            opacity: 1; /* Fade back in */
            visibility: visible; /* Become visible again */
        }
    }


    /* Responsive styling for cards */
    @media (max-width: 768px) {


        .card {
            width: 48%;
            max-width: 48%;
            min-height: 180px;
            max-height: 250px;
        }





        .title {
            font-size: 18px;
            font-family: Comic Sans MS, Arial Rounded MT Bold;
        }

        .description {
            font-size: 12px;
        }
    }

    @media (max-width: 480px) {

        .cardcontainer {
            flex-wrap: wrap; /* Allow wrapping of cards */
            justify-content: center; /* Center cards horizontally */
        }

        .card {
            width: 100%;
            max-width: 100%;
            min-height: 270px;
            max-height: 200px;
            position: relative;
            background-position: center;
            /* Ensure hover effect stays within the card */
            /*  transition: background 0.2s ease-out;*/
        }




        .cardone, .cardtwo, .cardthree {
            animation: alternateContent 10s infinite;
        }

        .info {
            animation: alternateDescription 30s infinite;
            top: -100%; /* Ensure the container starts from the top */
            width: 100%;
            height: 102%;
            background-color: rgba(255, 255, 255, 0.9);
            display: flex;
            flex-direction: column;
            justify-content: flex-start; /* Changed from center to flex-start */
            align-items: center;
            opacity: 0;
            visibility: hidden;
            transition: opacity 3s ease-in-out, visibility 3s ease-in-out;
            padding: 20px;
        }

        .title {
            font-size: 16px; /* Smaller font size for title */
            margin: 0; /* Remove any default margin */
            padding: 0; /* Remove any default padding */
            text-align: center;
            margin-bottom: 30px;
            font-family: Comic Sans MS, Arial Rounded MT Bold;
        }

        .description {
            font-size: 14px; /* Font size for description */
            margin-top: -18px;
            padding: 0; /* Remove any default padding */
            text-align: center;
            width: 100%; /* Ensure it takes the full width */
            font-family: Comic Sans MS, Arial Rounded MT Bold;
        }
    }





    /* Pricing page   */





    @media screen and (min-width: 550px) and (max-width: 1200px) {
        #adjustingpricingwrapper {
            display: flex;
            flex-direction: column; /* Stack them vertically */
            align-items: center; /* Center align the pricing tables */
        }

        #gprice-singlehomepage {
            width: 80%; /* Adjust width to be more reasonable */
            max-width: 500px; /* Set a maximum width */
            margin-bottom: 30px; /* Add margin to space between divs */
            position: relative; /* Keep position relative for proper alignment */
        }

        .pricing-table .content ul li {
            margin: 20px 0px !important;
            font-size: 20px !important;
            width: 401px !important;
            text-align: center !important;
        }
    }

    #adjustingpricingwrapper {
        line-height: 1.3em !important;
    }

    .wrapper {
        display: grid;
        grid-template-columns: repeat(4,1fr);
        grid-gap: 15px;
        margin: 20px;
        padding: 0px 20px;
    }

    .pricing-table {
        box-shadow: 0px 0px 18px #ccc;
        text-align: center;
        padding: 30px 0px;
        border-radius: 5px;
        position: relative;
        background-color: tomato;
    }

        .pricing-table .head {
            border-bottom: 1px solid #eee;
            padding-bottom: 60px;
            transition: all 0.5s ease;
        }

        .pricing-table:hover .head {
            border-bottom: 1px solid #8E2DE2;
        }

        .pricing-table .head .title {
            font-size: 38px;
            font-weight: 700;
            color: #fff;
        }

        .pricing-table .content .price {
            background: linear-gradient(to right, #8E2DE2 0%, #4A00E0 100%);
            width: 120px;
            height: 120px;
            margin: auto;
            line-height: 80px;
            border-radius: 50%;
            border: 5px solid #fff;
            box-shadow: 0px 0px 10px #ccc;
            margin-top: -50px;
            transition: all 0.5s ease;
        }

        .pricing-table:hover .content .price {
            transform: scale(1.2);
        }

        .pricing-table .content .price h1 {
            color: #fff;
            font-size: 23px;
            font-weight: 700;
            text-align: center;
        }

        .pricing-table .content ul {
            list-style-type: none;
            height: 250px;
        }

            .pricing-table .content ul li {
                margin: 15px 0px;
                font-size: 15px;
                color: #fff;
                text-align: left;
                width: 235px;
            }

    @media (max-width: 849px) {



        .info {
            animation: alternateDescription 30s infinite;
            top: -268px;
            width: 100%;
            height: 102%;
            background-color: rgba(255, 255, 255, 0.9);
            display: flex;
            flex-direction: column;
            justify-content: flex-start; /* Changed from center to flex-start */
            align-items: center;
            opacity: 0;
            visibility: hidden;
            transition: opacity 3s ease-in-out, visibility 3s ease-in-out;
        }
    }


    @media (min-width: 911px) and (max-width: 1100px) {
        #ipadadjustingforcard {
            top: -295px;
            scale: 101%;
        }
    }

    @media (min-width: 800px) and (max-width: 910px) {
        #ipadadjustingforcard {
            scale: 106%;
            top: -317px;
        }
    }

    @media (min-width: 650px) and (max-width: 795px) {
        #ipadadjustingforcard {
            top: -252px;
        }

        .title {
            padding: 10px;
            font-size: 12px;
        }
    }


    @media (max-width: 480px) {
        .pricing-table .content ul li {
            color: #fff;
            font-size: 17px;
            margin: 14px -20px;
            text-align: left;
            width: 100%;
            font-family: Comic Sans MS, Arial Rounded MT Bold;
        }
    }

    .pricing-table .content .sign-up {
        background: linear-gradient(to right, #9ac0ea8c 0%, #ec3f20c7 100%);
        border-radius: 40px;
        font-weight: 500;
        position: relative;
        display: inline-block;
    }


    .pricing-table .btn {
        color: #2c3e50;
        padding: 14px 40px;
        display: inline-block;
        text-align: center;
        font-weight: 600;
        -webkit-transition: all 0.3s linear;
        -moz-transition: all 0.3 linear;
        transition: all 0.3 linear;
        border: none;
        font-size: 14px;
        text-transform: capitalize;
        position: relative;
        text-decoration: none;
        margin: 2px;
        z-index: 9999;
        text-decoration: none;
        border-radius: 50px;
    }

        .pricing-table .btn:hover {
            box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.3);
        }

        .pricing-table .btn.bordered {
            z-index: 50;
            color: #2c3e50 !important;
        }

    .pricing-table:hover .btn.bordered {
        /*        color: #fff !important;*/
    }

    .pricing-table .btn.bordered::before {
        background-color: red;
    }

    .pricing-table .btn.bordered:after {
        background: #f8e888 none repeat scroll 0 0;
        border-radius: 50px;
        content: "";
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        -webkit-transition: all 0.3s linear;
        width: 100%;
        z-index: -1;
        -webkit-transform: scale(1);
        -moz-transform: scale(1);
        transform: scale(1);
    }

    .pricing-table:hover .btn.bordered:after {
        opacity: 0;
        transform: scale(0);
    }

    .forallpaidpacakges {
        text-align: center;
        font-size: x-large;
        color: #2c3e50;
    }



    @media screen and (max-width:768px) {
        .wrapper {
            grid-template-columns: repeat(2,1fr);
        }
    }

    @media screen and (max-width:600px) {
        .wrapper {
            grid-template-columns: 1fr;
        }
    }

    @media screen and (max-width:810px) {
        .wrapper {
            grid-template-columns: auto;
        }
    }

    @media screen and (max-width:480px) {
        .forallpaidpacakges {
            font-size: large;
        }
    }

    #aboutusfrontpagetext {
        font-size: 20px;
    }






    #familieschooseusheaders {
    }

    #familieschooseusText {
        font-size: 1.3em;
        margin-top: -26px;
        background-color: #f8e888;
        color: #15172b;
        border-radius: 10px;
        padding: 10px;
    }

    #frequentlyaskedheader {
        background-color: #FFAF45;
        color: #2c3e50;
        font-size: 1.5em;
    }


    /* subscription manage page */
    .wrapper-sub {
        display: flex;
        grid-gap: 24px;
        margin: -33px;
        padding: 70px 48px;
    }

    .pricing-table-sub {
        box-shadow: 0px 0px 18px #ccc;
        text-align: center;
        padding: 30px 0px;
        border-radius: 5px;
        position: relative;
        background-color: tomato;
        width: 30%;
    }

        .pricing-table-sub .head-sub {
            border-bottom: 1px solid #eee;
            padding-bottom: 50px;
            transition: all 0.5s ease;
            margin-top: -55px;
        }

        .pricing-table-sub:hover .head-sub {
            border-bottom: 1px solid #8E2DE2;
        }

        .pricing-table-sub .head-sub .title-sub {
            margin-bottom: 12px;
            font-size: 30px;
            font-weight: 700;
            color: #fff;
            background-color: unset;
        }

        .pricing-table-sub .content-sub .price-sub {
            background: linear-gradient(to right, #8E2DE2 0%, #4A00E0 100%);
            width: 100px;
            height: 100px;
            margin: auto;
            line-height: 80px;
            border-radius: 50%;
            border: 5px solid #fff;
            box-shadow: 0px 0px 10px #ccc;
            margin-top: -50px;
            transition: all 0.5s ease;
        }

        .pricing-table-sub:hover .content-sub .price-sub {
            transform: scale(1.2);
        }

        .pricing-table-sub .content-sub .price-sub h1 {
            color: #fff;
            font-size: 18px;
            font-weight: 700;
            text-align: center;
            letter-spacing: unset;
            margin-top: 6px;
        }

        .pricing-table-sub .content-sub ul {
            list-style-type: none;
            margin-bottom: 20px;
        }

            .pricing-table-sub .content-sub ul li {
                margin: 20px 0px;
                font-size: 14px;
                color: #fff;
                text-align: left;
            }

    @media (max-width: 480px) {


        .pricing-table-sub {
            width: 82%;
        }


            .pricing-table-sub .content-sub ul li {
                color: #fff;
                font-size: 13px;
                margin: 14px -20px;
                text-align: left;
                width: 100%;
                font-family: Comic Sans MS, Arial Rounded MT Bold;
            }

            .pricing-table-sub .btn-sub.bordered-sub:after {
                background: #4b01e0 !important;
            }
    }

    .pricing-table-sub .content-sub .sign-up-sub {
        /* background: linear-gradient(to right, #8E2DE2 0%, #4A00E0 100%);*/
        border-radius: 40px;
        font-weight: 500;
        position: relative;
        display: inline-block;
    }


    .pricing-table-sub .btn-sub {
        color: #fff;
        padding: 14px 40px;
        display: inline-block;
        text-align: center;
        font-weight: 600;
        -webkit-transition: all 0.3s linear;
        -moz-transition: all 0.3 linear;
        transition: all 0.3 linear;
        border: none;
        font-size: 14px;
        text-transform: capitalize;
        position: relative;
        text-decoration: none;
        margin: 2px;
        z-index: 9999;
        text-decoration: none;
        border-radius: 50px;
        background-color: #2c3e50;
    }

        .pricing-table-sub .btn-sub:hover {
            box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.3);
        }

        .pricing-table-sub .btn-sub.bordered-sub {
            z-index: 50;
            color: #f8e888 !important;
        }

    .pricing-table-sub:hover .btn-sub.bordered-sub {
        /*        color: #fff !important;*/
    }

    .pricing-table-sub .btn-sub.bordered-sub::before {
        background-color: red;
    }

    .pricing-table-sub .btn.bordered-sub:after {
        background: #fff none repeat scroll 0 0;
        border-radius: 50px;
        content: "";
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        -webkit-transition: all 0.3s linear;
        width: 100%;
        z-index: -1;
        -webkit-transform: scale(1);
        -moz-transform: scale(1);
        transform: scale(1);
    }

    .pricing-table-sub:hover .btn-sub.bordered-sub:after {
        opacity: 0;
        transform: scale(0);
    }

    @media screen and (max-width:480px) {
        .wrapper-sub {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
        }
    }

    @media screen and (max-width:768px) {
        .wrapper-sub {
            grid-template-columns: repeat(2,1fr);
        }
    }

    @media screen and (max-width:600px) {
        .wrapper-sub {
            grid-template-columns: 1fr;
        }
    }

    @media screen and (max-width:810px) {
        .wrapper-sub {
            grid-template-columns: auto;
        }
    }



    #monthlyPackages {
        display: grid;
        padding-bottom: 12px;
        padding-left: 18px;
    }

    #annualPackages {
        display: grid;
        padding-bottom: 12px;
        padding-left: 18px;
    }









    /*sign up form free*/
    body {
        /*align-items: center;
    background-color: #000;
    display: flex;
    justify-content: center;
    height: 100vh;*/
    }

    #refcolor {
        color: #08d;
        text-decoration: underline;
    }


.manage-sub-link-style {
    color: #007bff;
}






@media screen and (max-width: 1070px) {
    #resetformid {
            height: 95vh;
        }

        #resetformidloginadjuster {
            max-width: 273px;
        }
    }

    @media screen and (max-width: 850px) and (max-height:450px) {
        #resetformid {
            height: 110vh !important;
        }
    }

    @media screen and (max-width: 850px) {
        #resetformid {
            height: 55vh;
        }
    }

    @media screen and (max-width: 790px) {
        #resetformid {
            height: 53vh;
        }
    }

    #loginsectionform {
        margin-top: -71px;
    }

    .loginform {
        background-color: #15172b;
        border-radius: 20px;
        box-sizing: border-box;
        padding: 20px;
        width: 420px;
        margin: auto;
    }

    @media screen and (max-width: 790px) {

        .loginform {
            max-width: 260px;
        }

        #signupformadjuster {
            width: auto;
            height: 622px;
        }
        
    }

    @media screen and (min-width: 795px) {
        #loginpagesection {
            max-width: 650px;
        }
    }

    #loginpagesectionheader {
        color: #f0f8ff;
        margin-top: -35px;
        font-size: 4em;
        margin-bottom: 41px;
    }

.larger-box-gift {
    height: 80px  !important; /* Adjust the height as needed */
}

#sendgiftsectionheader {
    color: #f0f8ff;
    margin-top: -35px;
    font-size: 4em;
    margin-bottom: 41px;
}
@media screen and (max-width: 450px) {
    #sendgiftsectionheader {
        font-size: 3em;
    }
    }

    @media screen and (min-width: 795px) {
        #signuppagesection {
            max-width: 650px;
        }
    }

    #signuppagesectionheader {
        color: #f0f8ff;
        margin-top: -35px;
        font-size: 4em;
        margin-bottom: 41px;
    }


    .form {
        background-color: #15172b;
        border-radius: 20px;
        box-sizing: border-box;
        height: 596px;
        padding: 20px;
        width: 420px;
        margin: auto;
    }

    .signuptitle {
        color: #eee;
        font-family: sans-serif;
        font-size: 36px;
        font-weight: 600;
        margin-top: 30px;
    }

    .subtitle {
        color: #eee;
        font-family: sans-serif;
        font-size: 16px;
        font-weight: 600;
        margin-top: 10px;
    }

    .terms-checkbox {
        color: #eee;
        font-family: sans-serif;
        font-size: 16px;
        font-weight: 600;
    }

    .input-container {
        height: 50px;
        position: relative;
        width: 100%;
    }

    .ic1 {
        margin-top: 40px;
    }

    .ic2 {
        margin-top: 30px;
    }

    .input {
        background-color: #2f324ff7;
        border-radius: 12px;
        border: 0;
        box-sizing: border-box;
        color: #eee;
        font-size: 18px;
        height: 100%;
        outline: 0;
        padding: 4px 20px 0;
        width: 100%;
    }

    .cut {
        background-color: #15172b;
        border-radius: 10px;
        height: 20px;
        left: 20px;
        position: absolute;
        top: -20px;
        transform: translateY(0);
        transition: transform 200ms;
        width: 76px;
    }

    .cut-short {
        width: 50px;
    }

    /*
    it did not work out perfectly, maybe try java instead
    .input:focus ~ .cut,
.input:not(:placeholder-shown) ~ .cut {
  transform: translateY(8px);
}

    .input:focus ~ .placeholder,
.input:not(:placeholder-shown) ~ .placeholder {
  transform: translateY(-30px) translateX(10px) scale(0.75);
}

.input:not(:placeholder-shown) ~ .placeholder {
  color: #808097;
}

.input:focus ~ .placeholder {
  color: #dc2f55;
}

   


.input:focus ~ .cut,
.input:not(::placeholder) ~ .cut {
    transform: translateY(8px);
} */

    .placeholder {
        color: #65657b;
        font-family: sans-serif;
        left: 20px;
        line-height: 14px;
        pointer-events: none;
        position: initial; /*it was absolute*/
        transform-origin: 0 50%;
        transition: transform 200ms, color 200ms;
        top: 20px;
    }

    /*
.input:focus ~ .placeholder,
.input:not(::placeholder) ~ .placeholder {
    transform: translateY(-30px) translateX(10px) scale(0.75);
}

.input:not(::placeholder) ~ .placeholder {
    color: #808097;
}

.input:focus ~ .placeholder {
    color: #dc2f55;
} */

    .submit {
        background-color: #08d;
        border-radius: 12px;
        border: 0;
        box-sizing: border-box;
        color: #eee;
        cursor: pointer;
        font-size: 18px;
        height: 50px;
        margin-top: 38px;
        text-align: center;
        width: 100%;
    }

        .submit:active {
            background-color: #06b;
        }


    .success-messagelogin {
        position: relative;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: #4caf50; /* Green background color */
        color: white; /* White text color */
        padding: 25px 30px; /* Padding around the text */
        border-radius: 10px; /* Rounded corners */
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Box shadow for a subtle effect */
        z-index: 999; /* Ensure the message appears on top of other content */
        font-size: 25px; /* Font size */
    }

        /* Hover effect */
        .success-messagelogin:hover {
            background-color: #45a049; /* Darker green on hover */
            cursor: pointer;
        }


    /* Dashbaord buttons*/


    #bodycontainer {
        position: relative !important;
        display: flex !important;
        justify-content: center;
        align-items: center;
        min-height: 35vh;
        background: linear-gradient(to bottom,tomato, moccasin) !important; /*fiolet*/
        border-bottom-right-radius: 30px;
        border-bottom-left-radius: 30px;
    }

    @media screen and (max-width: 480px) {
        #bodycontainer {
            min-height: 25vh;
            display: flex !important;
            flex-wrap: wrap;
        }
    }

    @media screen and (max-width:810px) {
        #bodycontainer {
            display: flex;
            margin: 15px;
        }
    }

    @media (max-width: 480px) {
        .Dashboardcontainer .btn {
            width: 100%; /* Ensure buttons stack on small screens */
            margin: 5px 0;
        }
    }

    .Dashboardcontainer {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
        flex-direction: row;
    }

        .Dashboardcontainer .btn {
            position: relative;
            top: 0;
            left: 0;
            width: 100%;
            height: 50px;
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 5px;
            max-width: 250px;
        }

            .Dashboardcontainer .btn a {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                background: rgba(255, 255, 255, 0.05);
                box-shadow: 0 15px 15px rgba(0, 0, 0, 0.3);
                border-bottom: 1px solid rgba(255, 255, 255, 0.1);
                border-top: 1px solid rgba(255, 255, 255, 0.1);
                border-radius: 30px;
                padding: 10px;
                letter-spacing: 1px;
                text-decoration: none;
                overflow: hidden;
                color: #1b2f08;
                font-weight: 400px;
                z-index: 1;
                transition: 0.5s;
                backdrop-filter: blur(15px);
            }

            .Dashboardcontainer .btn:hover a {
                letter-spacing: 3px;
            }

            .Dashboardcontainer .btn a::before {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                width: 50%;
                height: 100%;
                background: linear-gradient(to left, rgba(255, 255, 255, 0.15), transparent);
                transform: skewX(45deg) translate(0);
                transition: 0.5s;
                filter: blur(0px);
            }

            .Dashboardcontainer .btn:hover a::before {
                transform: skewX(45deg) translate(200px);
            }

            .Dashboardcontainer .btn::before {
                content: "";
                position: absolute;
                left: 50%;
                transform: translatex(-50%);
                bottom: -5px;
                width: 30px;
                height: 10px;
                background: #f00;
                border-radius: 10px;
                transition: 0.5s;
                transition-delay: 0.5;
            }

            .Dashboardcontainer .btn:hover::before /*lightup button*/ {
                bottom: 0;
                height: 50%;
                width: 80%;
                border-radius: 30px;
            }

            .Dashboardcontainer .btn::after {
                content: "";
                position: absolute;
                left: 50%;
                transform: translatex(-50%);
                top: -5px;
                width: 30px;
                height: 10px;
                background: #f00;
                border-radius: 10px;
                transition: 0.5s;
                transition-delay: 0.5;
            }

            .Dashboardcontainer .btn:hover::after /*lightup button*/ {
                top: 0;
                height: 50%;
                width: 80%;
                border-radius: 30px;
            }

            .Dashboardcontainer .btn:nth-child(1)::before, /*chnage 1*/
            .Dashboardcontainer .btn:nth-child(1)::after {
                background: #f8e888;
                box-shadow: 0 0 5px #f8e888, 0 0 15px #f8e888, 0 0 30px #f8e888, 0 0 60px #f8e888;
            }

            .Dashboardcontainer .btn:nth-child(2)::before, /* 2*/
            .Dashboardcontainer .btn:nth-child(2)::after {
                background: #f8e888;
                box-shadow: 0 0 5px #f8e888, 0 0 15px #f8e888, 0 0 30px #f8e888, 0 0 60px #f8e888;
            }

            .Dashboardcontainer .btn:nth-child(3)::before, /* 3*/
            .Dashboardcontainer .btn:nth-child(3)::after {
                background: #f8e888;
                box-shadow: 0 0 5px #f8e888, 0 0 15px #f8e888, 0 0 30px #f8e888, 0 0 60px #f8e888;
            }


    /*packagecheckout button*/
    .buttons-container {
        width: 100%;
        height: 30vh;
        display: flex;
        align-items: center;
        justify-content: center;
    }


    .button-arounder {
        font-size: 2rem;
        background: hsl(190deg, 30%, 15%);
        color: hsl(190deg, 10%, 95%);
        box-shadow: 0 0px 0px hsla(190deg, 15%, 5%, .2);
        transform: translateY(0);
        border-top-left-radius: 0px;
        border-top-right-radius: 0px;
        border-bottom-left-radius: 0px;
        border-bottom-right-radius: 0px;
        --dur: .15s;
        --delay: .15s;
        --radius: 16px;
        transition: border-top-left-radius var(--dur) var(--delay) ease-out, border-top-right-radius var(--dur) .30s ease-out, border-bottom-right-radius var(--dur) .45s ease-out, border-bottom-left-radius var(--dur) .60s ease-out, box-shadow .60s ease-out, transform .60s ease-out, background .60s steps(4, jump-end);
    }

        .button-arounder:hover,
        .button-arounder:focus {
            box-shadow: 0 4px 8px hsla(190deg, 15%, 5%, .2);
            transform: translateY(-4px);
            background: hsl(230deg, 50%, 45%);
            border-top-left-radius: var(--radius);
            border-top-right-radius: var(--radius);
            border-bottom-left-radius: var(--radius);
            border-bottom-right-radius: var(--radius);
        }



    #packageSelect {
        font-family: 'Arial', sans-serif; /* Consistent font with the paragraph */
        font-size: 1.1rem; /* Slightly larger font for better readability */
        color: #333; /* Darker text color */
        background-color: #fff; /* White background */
        border: 2px solid #4CAF50; /* Border matching the theme color */
        border-radius: 5px; /* Rounded corners */
        padding: 10px; /* Padding for better spacing */
        margin: 10px 0; /* Margin to separate it from other elements */
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow for a lifted effect */
        transition: all 0.3s ease; /* Smooth transition for interactions */
        width: 100%; /* Full width for better usability */
        max-width: 400px; /* Maximum width to prevent it from becoming too wide */
        cursor: pointer; /* Pointer cursor on hover */
    }

        #packageSelect:focus {
            outline: none; /* Remove default outline */
            border-color: #388E3C; /* Darker green border on focus */
            box-shadow: 0 0 5px rgba(56, 142, 60, 0.5); /* Highlight effect on focus */
        }

        #packageSelect option {
            padding: 10px; /* Padding for options */
        }





    #selectpackagetext {
        font-family: 'Arial', sans-serif;
        font-size: 1.2rem; /* Slightly larger font for better readability */
        color: #333; /* Darker shade for the text color */
        background-color: #f9f9f9; /* Light background to highlight the text */
        padding: 20px; /* Padding around the text */
        border-left: 5px solid #4CAF50; /* Left border for visual emphasis */
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow for a lifted effect */
        margin: 20px 0; /* Margin to separate it from other elements */
        line-height: 1.6; /* Improved line spacing for readability */
        text-align: left; /* Align text to the left */
        border-radius: 5px; /* Rounded corners */
        transition: all 0.3s ease; /* Smooth transition for hover effect */
    }

        #selectpackagetext a {
            color: #4CAF50; /* Link color to match the border */
            text-decoration: none; /* Remove underline from link */
            font-weight: bold; /* Make the link bold */
        }

            #selectpackagetext a:hover {
                text-decoration: underline; /* Underline link on hover */
            }

    /* general libraryoptions not logged in*/
    @media screen and (min-width: 768px) and (max-width: 1090px) {

        #AppContainer {
            /*    height: calc(92vh - 96px);*/
            overflow-y: auto;
        }
    }

    /* general libraryoptions not logged in*/
    @media screen and (max-width: 480px) {
        #generallibraryoptionnotloggedinforsmallscreens {
            margin-top: 60px !important;
        }
    }
    /* general libraryoptions not logged in*/
    @media screen and (max-width: 1085px) {
        #generallibraryoptionnotloggedinforsmallscreens {
            margin-bottom: 200px;
            margin-top: 190px;
        }
    }
    /* general libraryoptions not logged in*/
    @media screen and (min-width: 1150px) {
        #generallibraryoptionnotloggedinforsmallscreens {
            margin-top: 55px;
            margin-bottom: 56px;
        }
    }


    /*Dashboard Book Selection*/
    .containerbookselection {
        width: 100%;
        display: flex;
        justify-content: center;
        height: 350px;
        gap: 10px;
    }

        .containerbookselection > div {
            flex: 0 0 120px;
            border-radius: 0.5rem;
            transition: 0.5s ease-in-out;
            cursor: pointer;
            box-shadow: 1px 5px 15px #1e0e3e;
            position: relative;
            overflow: hidden;
            min-width: 190px;
        }

            .containerbookselection > div:nth-of-type(1) {
                background: url('./dragon_generallibrary.png') no-repeat 50%;
                background-size: 350px auto;
            }

            .containerbookselection > div:nth-of-type(2) {
                background: url('./family_generallibrary.png') no-repeat 50%;
                background-size: 350px auto;
            }

            .containerbookselection > div:nth-of-type(3) {
                background: url('./car_generallibrary.png') no-repeat 50%;
                background-size: 366px auto;
            }

            .containerbookselection > div:nth-of-type(4) {
                background: url('./connectdeeperlevel.png') no-repeat 50%;
                background-size: 350px auto;
            }


            .containerbookselection > div .contentbookselection {
                font-size: 1rem;
                color: #fff;
                display: flex;
                align-items: center;
                padding: 1px;
                opacity: 0;
                flex-direction: column;
                height: 100%;
                justify-content: flex-end;
                background: rgb(2, 2, 46);
                background: linear-gradient(0deg, rgba(2, 2, 46, 0.6755077030812324) 0%, rgba(255, 255, 255, 0) 100%);
                transform: translateY(100%);
                transition: opacity 0.5s ease-in-out, transform 0.5s 0.2s;
                visibility: hidden;
            }

            .containerbookselection > div span {
                display: block;
                margin-top: 5px;
                font-size: 1.2rem;
            }

            .containerbookselection > div:hover {
                flex: 0 0 250px;
                box-shadow: 1px 3px 15px #7645d8;
                transform: translateY(-30px);
            }

                .containerbookselection > div:hover .contentbookselection {
                    opacity: 1;
                    transform: translateY(0%);
                    visibility: visible;
                }

    @media screen and (max-width: 480px) {

        .containerbookselection {
            flex-wrap: wrap;
            height: 10%;
        }

            .containerbookselection > div {
                min-width: 290px;
                height: 250px;
            }

                .containerbookselection > div .contentbookselection {
                    opacity: 1; /* Make the content visible */
                    transform: translateY(0%); /* Position it correctly */
                    visibility: visible; /* Ensure it's visible */
                    justify-content: center; /* Center the content */
                    background: none; /* Remove background gradient for clarity */
                }

                    .containerbookselection > div .contentbookselection h2 {
                        position: absolute; /* Position it over the image */
                        top: 90%; /* Center vertically */
                        left: 50%; /* Center horizontally */
                        transform: translate(-50%, -50%); /* Adjust position */
                        background-color: rgba(0, 0, 0, 0.5); /* Add background for readability */
                        padding: 10px; /* Add padding */
                        border-radius: 10px; /* Round corners */
                        width: max-content;
                    }
    }




    /*second line book selection*/
    .secondcontainerbookselection {
        width: 100%;
        display: flex;
        justify-content: center;
        height: 350px;
        gap: 10px;
        margin-top: 35px;
    }

        .secondcontainerbookselection > div {
            flex: 0 0 120px;
            border-radius: 0.5rem;
            transition: 0.5s ease-in-out;
            cursor: pointer;
            box-shadow: 1px 5px 15px #1e0e3e;
            position: relative;
            overflow: hidden;
            min-width: 190px;
        }

            .secondcontainerbookselection > div:nth-of-type(1) {
                background: url('./animal_generallibrary.png') no-repeat 50%;
                background-size: 350px auto;
            }

            .secondcontainerbookselection > div:nth-of-type(2) {
                background: url('./superhero_generallibrary.png') no-repeat 50%;
                background-size: 350px auto;
            }

            .secondcontainerbookselection > div:nth-of-type(3) {
                background: url('./varioustales_generallibrary.png') no-repeat 50%;
                background-size: 350px auto;
            }

            .secondcontainerbookselection > div:nth-of-type(4) {
                background: url('./connectdeeperlevel.png') no-repeat 50%;
                background-size: 350px auto;
            }


            .secondcontainerbookselection > div .secondcontentbookselection {
                font-size: 1rem;
                color: #fff;
                display: flex;
                align-items: center;
                padding: 1px;
                opacity: 0;
                flex-direction: column;
                height: 100%;
                justify-content: flex-end;
                background: rgb(2, 2, 46);
                background: linear-gradient(0deg, rgba(2, 2, 46, 0.6755077030812324) 0%, rgba(255, 255, 255, 0) 100%);
                transform: translateY(100%);
                transition: opacity 0.5s ease-in-out, transform 0.5s 0.2s;
                visibility: hidden;
            }

            .secondcontainerbookselection > div span {
                display: block;
                margin-top: 5px;
                font-size: 1.2rem;
            }

            .secondcontainerbookselection > div:hover {
                flex: 0 0 250px;
                box-shadow: 1px 3px 15px #7645d8;
                transform: translateY(-30px);
            }

                .secondcontainerbookselection > div:hover .secondcontentbookselection {
                    opacity: 1;
                    transform: translateY(0%);
                    visibility: visible;
                }

    @media screen and (max-width: 480px) {

        .secondcontainerbookselection {
            flex-wrap: wrap;
            height: 10%;
        }

            .secondcontainerbookselection > div {
                min-width: 290px;
                height: 250px;
            }


                .secondcontainerbookselection > div .secondcontentbookselection {
                    opacity: 1; /* Make the content visible */
                    transform: translateY(0%); /* Position it correctly */
                    visibility: visible; /* Ensure it's visible */
                    justify-content: center; /* Center the content */
                    background: none; /* Remove background gradient for clarity */
                }

                    .secondcontainerbookselection > div .secondcontentbookselection h2 {
                        position: absolute; /* Position it over the image */
                        top: 90%; /* Center vertically */
                        left: 50%; /* Center horizontally */
                        transform: translate(-50%, -50%); /* Adjust position */
                        background-color: rgba(0, 0, 0, 0.5); /* Add background for readability */
                        padding: 10px; /* Add padding */
                        border-radius: 10px; /* Round corners */
                        width: max-content;
                    }
    }


    /*H1 for general library page*/
    #gnerallibraryoptionsheader {
        color: #f0f8ff;
        font-size: 3.5em;
        margin-bottom: 100px;
        margin-top: 14px;
        text-align: center;
    }

    @media screen and (max-width: 480px) {

        #gnerallibraryoptionsheader {
            font-size: 20px;
        }
    }



    /*Gwow section*/
    @media screen and (max-width: 750px) {
        #gwowsection {
            display: none;
        }
    }

    #bodygwow {
        display: flex;
        justify-content: center;
        align-items: center;
        min-height: 100vh;
    }

    #containergwow {
        position: relative;
        width: 1160px;
        display: flex;
        justify-content: center;
        flex-wrap: initial;
        transform-style: preserve-3d;
        perspective: 500px;
        margin: auto;
    }

        #containergwow #boxgwow {
            position: relative;
            width: 275px;
            height: 275px;
            background: #000;
            transition: 0.5s;
            transform-style: preserve-3d;
            overflow: hidden;
            margin-right: 15px;
            margin-top: 45px;
        }

        #containergwow:hover #boxgwow {
            transform: rotateY(25deg);
        }

        #containergwow #boxgwow:hover ~ #boxgwow {
            transform: rotateY(-25deg);
        }

        #containergwow #boxgwow:hover {
            transform: rotateY(0deg) scale(1.3);
            z-index: 1;
            box-shadow: 0 25px 40px rgba(.3,0,0,0.3);
        }

        #containergwow #boxgwow .imgBx {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            /* background: linear-gradient(180deg,#fff,#400);here cahnge color*/
        }

        #containergwow #boxgwow .firstboxgwowbackgroundcolor {
            background: #59D5E0;
        }

        #containergwow #boxgwow .secondboxgwowbackgroundcolor {
            background: #FCE22A;
        }

        #containergwow #boxgwow .thirdboxgwowbackgroundcolor {
            background: #F94A29;
        }

        #containergwow #boxgwow .fourthboxgwowbackgroundcolor {
            background: #D61355;
        }

        #containergwow #boxgwow .specificcolorone:before {
            background: linear-gradient(135deg, #2E8BC0 0%, #59D5E0 100%) !important;
        }

        #containergwow #boxgwow .specificcolortwo:before {
            background: linear-gradient(135deg, #F6D70F 0%, #FCE22A 100%) !important;
        }

        #containergwow #boxgwow .specificcolorthree:before {
            background: linear-gradient(135deg, #E53426 0%, #F94A29 100%) !important;
        }

        #containergwow #boxgwow .specificcolorfour:before {
            background: linear-gradient(135deg, #B60E4E 0%, #D61355 100%) !important;
        }

        #containergwow #boxgwow .imgBx:before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: linear-gradient(180deg,#fff,#400);
            z-index: 1;
            opacity: 0;
            transition: 0.5s;
            mix-blend-mode: multiply;
        }

        #containergwow #boxgwow:hover .imgBx:before {
            opacity: 1;
        }

        #containergwow #boxgwow .imgBx img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }

        #containergwow #boxgwow .bgText {
            color: #fff;
            font-size: 120px;
            font-weight: bold;
            text-transform: uppercase;
        }


        #containergwow #boxgwow .content {
            position: absolute;
            top: 20px;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 1;
            display: flex;
            padding: 20px;
            align-items: flex-end;
            box-sizing: border-box;
        }

            #containergwow #boxgwow .content h2 {
                color: #fff;
                transition: 0.5s;
                text-transform: uppercase;
                margin-bottom: 5px;
                font-size: 25px;
                transform: translateY(200px);
                transition-delay: 0.3s;
            }

        #containergwow #boxgwow:hover .content h2 {
            transform: translateY(0px);
        }

        #containergwow #boxgwow .content p {
            color: #fff;
            transition: 0.5s;
            font-size: 16px;
            transform: translateY(200px);
            transition-delay: 0.4s;
        }

        #containergwow #boxgwow:hover .content p {
            transform: translateY(0px);
        }


    #notloggedinadjusterforsectionforappjs {
        margin: 256px auto !important;
    }


#careers-page {
    font-family: 'Arial', sans-serif;
    max-width: 800px;
    margin: 0 auto;
    padding: 40px;
    background-color: #f9f9f9;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}


@media screen and (max-width: 720px) {
    #careers-header {
        font-size: 1.5rem;
    }
    }
/* Header Styling */
#careers-header {
    font-size: 2rem;
    text-align: center;
    color: #fbf2a6;
    margin-bottom: 20px;
}

/* Intro Paragraph Styling */
#careers-intro {
    font-size: 1.2rem;
    text-align: center;
    color: #fbf2a6;
    margin-bottom: 30px;
    line-height: 1.6;
}

/* Instructions Styling */
#careers-instructions {
    margin-top: 20px;
    font-size: 1rem;
    color: #444;
    line-height: 1.8;
}

/* Individual Steps Styling */
.careers-step {
    margin-bottom: 20px;
    padding: 15px;
    border-left: 5px solid #007bff;
    background-color: #fff;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    font-size: 1rem;
    color: #2c3e50 !important;
}

    /* Emphasize Keywords */
    .careers-step strong {
        color: #007bff;
        font-weight: bold;
    }