

#mainfooter {
    flex: 2;
    background: bisque;
    flex-direction: column;
    font-size: 1.1rem;
   
}

section {
    padding: 20px;
    flex-grow: 1; /*magical line*/
}

#footertext {
    color: #f8e888;
    order: 1;
}


footer {
    padding: 10px;
    /*  background: #e74c3c;*/
    margin-top: auto;
    position: relative;
    background-color: #2c3e50;
    color: #f8e888;
    max-height: 80vh;
}

.footer-text {
    color: #f8e888;
    margin-top: 15px;
    max-width: 390px;
}


code {
    background: yellow;
    padding: 2px 5px;
    white-space: nowrap;
}



body {
    margin: 0;
    padding: 0;
    background: #ebebeb;
}

@media screen and (min-width: 1250px) {
    footer ul {
        transform: translate(35%,-19%);
    }
}
@media screen and (min-width: 860px) and (max-width: 1200px){
    footer ul {
        transform: translate(-2%,0%);
    }
}

    footer ul {
    /*position: absolute;*/
    /* top: 60%;*/
    left: 50%;
    /*magical line , wher you control social facebook icons*/
    margin: 0;
    padding: 0;
    display: flex;
    margin-left: 5%;
    margin-top: 10%;
}

    footer ul li {
        list-style: none;
    }

        footer ul li a {
            display: block;
            position: relative;
            width: 100px;
            height: 100px;
            line-height: 100px;
            font-size: 40px;
            text-align: center;
            text-decoration: none;
            color: #f8e888 ;
            margin: 0 30px;
            transition: .5s;
        }

            footer ul li a span {
                position: absolute;
                transition: transform .5s;
            }

                footer ul li a span:nth-child(1),
                footer ul li a span:nth-child(3) {
                    width: 100%;
                    height: 3px;
                    background: #404040;
                }

                footer ul li a span:nth-child(1) {
                    top: 0;
                    left: 0;
                    transform-origin: right;
              
                }

            footer ul li a:hover span:nth-child(1) {
                transform: scaleX(0);
                transform-origin: left;
                transition: transform .5s;
            }

            footer ul li a span:nth-child(3) {
                bottom: 0;
                left: 0;
                transform-origin: left;
            }

            footer ul li a:hover span:nth-child(3) {
                transform: scaleX(0);
                transform-origin: right;
                transition: transform .5s;
            }

            footer ul li a span:nth-child(2),
            footer ul li a span:nth-child(4) {
                width: 3px;
                height: 100%;
                background: #404040;
            }

            footer ul li a span:nth-child(2) {
                top: 0;
                left: 0;
                transform: scale(0);
                transform-origin: bottom;
            }

            footer ul li a:hover span:nth-child(2) {
                transform: scale(1);
                transform-origin: top;
                transition: transform .5s;
            }

            footer ul li a span:nth-child(4) {
                top: 0;
                right: 0;
                transform: scale(0);
                transform-origin: top;
            }

            footer ul li a:hover span:nth-child(4) {
                transform: scale(1);
                transform-origin: bottom;
                transition: transform .5s;
            }

footer .facebook:hover {
    color: #3b5998;
}

    footer .facebook:hover span {
        background: #3b5998;
    }

footer .tiktok:hover {
    color: white;
}

    footer .tiktok:hover span {
        background: black;
    }

footer .instagram:hover {
    color: #c32aa3;
}

    footer .instagram:hover span {
        background: #c32aa3;
    }

footer .google:hover {
    color: #dd4b39;
}

    footer .google:hover span {
        background: #dd4b39;
    }

footer ul li a .tiktok {
    color: black;
}

footer ul li a:hover:nth-child(3) {
    color: #c32aa3;
}

footer ul li a:hover:nth-child(4) {
    color: #dd4b39;
}

@media screen and (max-width: 450px) {
    footer ul {
        transform: translate(2%,119%);
    }
}

@media screen  (min-width:495) and (max-width: 850px) {
    #halffootertwo {
        scale: 185% !important;
        margin-top: 88px;
        margin-left: 60px;
    }
}

@media only screen and (min-width: 559px) and (max-width: 601px) and (min-height: 958px) and (max-height: 965px) {
    /* Add specific styles for iPad here */
   
    #textheadlinesecond {
        width: auto !important;
    }

    .slideshow-container {
        margin-top: 0vh !important;
    }

    .container {
        display: flex;
    }
}

@media only screen and (min-width: 767px) and (max-width: 769px) and (min-height: 1023px) and (max-height: 1025px) {
    /* Add specific styles for iPad here */
    #textheadlinesecond {
        width:auto !important;
    }
    .slideshow-container {
        margin-top: 0vh !important;
    }
    .container {
    display:flex;
    }
}




    /* Media Query for smaller screens */
    @media screen and (max-width: 850px) {
        #halffooterone {
            margin-left: 30px !important;
        }

        #halffootertwo {
            scale: 185% !important;
            margin-top: 88px;
            margin-left: 60px;
        }



        footer {
            display: flex; /* Ensuring footer content is displayed in a row */
            justify-content: space-between; /* Spacing out the content */
            align-items: center; /* Centering vertically */
            padding: 10px; /* Adding some padding for better spacing */
            height: auto;
        }

            footer ul {
                position: static; /* Remove absolute positioning */
                transform: none; /* Remove transformation */
                display: flex;
                justify-content: center; /* Center the social icons */
                margin: 0;
                padding: 0;
                margin-right: 35px;
            }

                footer ul li {
                    list-style: none;
                }

                    footer ul li a {
                        display: flex; /* Display icons in a row */
                        justify-content: center; /* Center horizontally */
                        align-items: center; /* Center vertically */
                        width: 50px; /* Adjust width of icons */
                        height: 50px; /* Adjust height of icons */
                        font-size: 24px; /* Adjust font size */
                        color: #f8e888;
                        margin: 0 10px; /* Adjust margin between icons */
                        transition: .5s;
                    }

                        footer ul li a span {
                            display: none; /* Hide spans for social icons */
                        }

        .footer-text {
            width: calc(100% - 10px); /* Adjusting width of text area */
            font-size: 17px; /* Adjusting font size */
            text-align: left; /* Aligning text to the left */

            bottom: 0;
            height: 7px;
        }
    }

    @media screen and (max-width: 480px) {
        .footer-text {
            width: 100%;
        }

        footer {
            display: block !important;
        }

        #halffootertwo {
            margin-left: 69px !important;
            margin-top: 50px !important;
        }
    }

    #language-select-color {
    }

    #halffooterone {
        margin-left: 100px;
    }

    #halffootertwo {
        scale: 105%;
    }

    #headhalffooter {
        display: inline-flex;
        margin-bottom: 15px;
        margin-top: 10px;
    }
    /*language picker*/
    #language-select {
        font-family: 'Arial', sans-serif; /* Example font family */
        font-size: 16px; /* Example font size */
        padding: 10px; /* Padding around the dropdown */
        border: 1px solid #ccc; /* Border color */
        border-radius: 5px; /* Rounded corners */
        -webkit-appearance: none; /* Remove default arrow in WebKit browsers */
        -moz-appearance: none; /* Remove default arrow in Firefox */
        background-color: #fff; /* Background color */
        background-image: linear-gradient(to bottom, #ffffff 0%, #f2f2f2 100%); /* Gradient background */
        background-repeat: no-repeat; /* No repeat */
        background-position: right 10px top 50%; /* Position of the arrow icon */
        cursor: pointer; /* Cursor style */
        outline: none; /* Remove outline */
        width: 130px; /* Example width */
    }

        /* Hover effect */
        #language-select:hover {
            border-color: #aaa; /* Border color on hover */
        }

        /* Focus effect */
        #language-select:focus {
            border-color: #4CAF50; /* Border color on focus */
            box-shadow: 0 0 5px rgba(81, 203, 238, 1); /* Example box shadow on focus */
        }





    /* Styling for the Renew Subscription button */
    #languagebutton {
        padding: 12px 40px;
        border-radius: 30px;
        font-size: 16px;
        font-weight: bold;
        text-transform: uppercase;
        letter-spacing: 1px;
        cursor: pointer;
        background-color: #27AE60; /* A pleasant green color to signal a positive action */
        color: white;
        border: none;
        box-shadow: 0 8px 15px rgba(39, 174, 96, 0.4);
        margin-top: 15px; /* Ensure spacing */
        text-align: center;
    }

        #languagebutton:hover {
            background-color: #2ECC71; /* Slightly lighter green on hover for visual feedback */
            box-shadow: 0 12px 20px rgba(39, 174, 96, 0.6);
            transform: translateY(-3px);
        }





    @media (max-width: 480px) {

        #headhalffooter {
            display: block;
            scale: 87%;
            margin-bottom: 0px;
            margin-top: 0px;
        }

        #languagebutton {
            max-width: 176px;
            padding: 11px 43px;
        }








        #halffooterone {
            margin-left: 90px;
        }
    }




@media (max-width: 880px) {
    #termsandcresize {
            margin-top: 70px;
        }
    }