.slideshow-container {
    position: relative;
    width: 100%;
    height: 100vh; /* Set height to full viewport height */
    overflow: hidden;
    margin-top: 10vh; /* Adjust top margin to leave space for the header */
    border-radius: 40px;
    margin-left: 2px;
}

.slideshow {
    list-style-type: none;
    border-radius: 40px;
    margin-top: auto;
}

    .slideshow,
    .slideshow:after {
        list-style-type: none;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 0;
    }

        .slideshow li span {
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0px;
            left: 0px;
            color: transparent;
            background-size: cover;
            background-position: 50% 50%;
            background-repeat: no-repeat;
            opacity: 0;
            z-index: 0;
            animation: imageAnimation 29s linear infinite 0s;
           margin-top:auto;
        }

        .slideshow li:nth-child(1) span {
            background-image: url('./slideshow1.png');
        }

        .slideshow li:nth-child(2) span {
            background-image: url('./slideshow2.png');
            animation-delay: 5s;
        }

        .slideshow li:nth-child(3) span {
            background-image: url('./slideshow3.png');
            animation-delay: 10s;
        }

        .slideshow li:nth-child(4) span {
            background-image: url('./slideshow4.png');
            animation-delay: 15s;
        }

        .slideshow li:nth-child(5) span {
            background-image: url('./slideshow5.png');
            animation-delay: 21s;
        }

@keyframes imageAnimation {
    0% {
        opacity: 0;
        animation-timing-function: ease-in;
    }

    8% {
        opacity: 1;
        animation-timing-function: ease-out;
    }

    17% {
        opacity: 1;
        animation-timing-function: ease-out; /* Maintain ease-out */
    }

    25% {
        opacity: 0;
        animation-timing-function: ease-in; /* Smooth fade out */
    }

    100% {
        opacity: 0;
    }
}

@keyframes titleAnimation {
    0% {
        opacity: 0
    }

    8% {
        opacity: 1
    }

    17% {
        opacity: 1
    }

    19% {
        opacity: 0
    }

    100% {
        opacity: 0
    }
}

.no-cssanimations .cb-slideshow li span {
    opacity: 1;
}

/* Responsive adjustments */
@media (max-width: 768px) {
    .slideshow-container {
        height: 50vh; /* Adjust the height for tablets */
        border-radius: 40px;
    }

    
}

@media (max-width: 480px) {
    .slideshow-container {
        height: 40vh; /* Adjust the height for mobile phones */
        margin-top: 5vh; /* Reduce top margin for better view */
        border-radius: 40px;
        background-size: cover;
    }

 
}
