* {
    box-sizing: border-box;
}

#logoHeaderMenu {
    background-image: url('./logoheaderleft.png');
    background-size: contain; /* Ensures the image fits the div */
    background-repeat: no-repeat; /* Prevents repeating */
    width: 27%; /* Adjust the width as needed */
    height: 90px; /* Adjust the height as needed */
}
#menubarnexttologo {
    width:80%
}

@media (max-width: 850px) {

    #logoHeaderMenu {
         background-repeat: round; /* Prevents repeating */
        width: 32%; /* Adjust the width as needed */
    }

    #menubarnexttologo {
        width: 74%
    }

}

@media (max-width: 450px) {

    #logoHeaderMenu {
        background-repeat: no-repeat;
        width: 60%;
    }

    #menubarnexttologo {
        width: 100%;
    }
}

html,
body {
    margin: 0;
    padding: 0;
}

body {

    background-color: #eee;
}

.container {
    display: flex;
    margin-top: 5px;
}

h1 {
    text-align: left;
    font-size: 30px;
    text-transform: uppercase;
    letter-spacing: 3px;
    color: #2C3E50;
}

.menu {
    list-style: none;
    margin: 0;
    padding: 0;
    width: 100%;
    height: 90px;
    margin: auto;
    position: relative;
    background-color: #2c3e50;
    z-index: 7;
    border-radius: 30px;
}

    .menu li {
        float: left;
        width: 25%;
        height: 100%;
        margin: 0;
        padding: 0;
    }

        .menu li.slider {
            width: 25%;
            height: 100%;
            position: absolute;
            left: 0;
            top: 0;
            background-color: tomato;
            z-index: 8;
            transition: left 0.9s , background-color 0.9s ;
            border-radius: 30px;
        }

    .menu a,
    .menu button {
        display: flex;
        width: 100%;
        height: 100%;
        justify-content: center;
        align-items: center;
        color: #fff;
        text-decoration: none;
        position: relative;
        font-size: 18px;
        z-index: 9;
    }

a.active,
button.active {
    background-color: #e74c3c;
    pointer-events: none;
}
.menu button {
    border: none; /* Remove border */
    background: none; /* Remove background */
    cursor: pointer; /* Change cursor to pointer */
    padding: 0;
}


.menu li:nth-child(1):hover ~ .slider {
    left: 0;
    background-color: #3498db;
}

.menu li:nth-child(2):hover ~ .slider {
    left: 25%;
    background-color: #9b59b6;
}

.menu li:nth-child(3):hover ~ .slider {
    left: 50%;
    background-color: #e67e22;
}

.menu li:nth-child(4):hover ~ .slider {
    left: 75%;
    background-color: #16a085;
}


.hoverheader {
    border: 8px solid;
    border-image: repeating-linear-gradient(135deg,#F8CA00 0 10px,#E97F02 0 20px,#BD1550 0 30px) 8;
    -webkit-mask: conic-gradient(from 180deg at top 8px right 8px, #0000 90deg,#000 0) var(--_i,200%) 0 /200% var(--_i,8px) border-box no-repeat, conic-gradient(at bottom 8px left 8px, #0000 90deg,#000 0) 0 var(--_i,200%)/var(--_i,8px) 200% border-box no-repeat, linear-gradient(#000 0 0) padding-box no-repeat;
    transition: .3s;
}

    .hoverheader:hover {
        --_i: 100%;
        color: #CC333F;
        transition: .3s;
    }

/* Remove slider effect on small screens */
@media (max-width: 768px) {
    .container {
        display: flex;
    }
    .menu {
        width: 100%;
    }
    .menu li.slider {
        display: none;
    }

    .menu li {
        width: 25% !important; /* Adjust width for smaller screens if needed */
    }

    .menu a,
    .menu button {
        font-size: 16px; /* Adjust font size for better readability */
    }
}

@media (max-width: 480px) {
    .container {
        display: block;
    }
    .menu {
    width:100%;
    }
    .menu li {
        width: 25% !important; /* Full width for mobile screens */
    }

    .menu a,
    .menu button {
        font-size: 12px; /* Further adjust font size for mobile screens */
    }

    .hoverheader {
     
        border:none;
    }
  
}