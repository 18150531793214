
.learn-more-section {
    padding: 20px;
    max-width: 900px;
    margin: 0 auto;
}

    .learn-more-section h2 {
        font-size: 2.5em;
        margin-bottom: 20px;
        text-align: center;
        color: #333;
    }

.faq-item {
    margin-bottom: 20px;
    padding: 15px;
    border: 1px solid #ddd;
    border-radius: 8px;
    background-color: #f9f9f9;
}

.faq-question {
    font-size: 1.4em;
    margin: 0;
    color: #007bff;
}

.faq-answer {
    font-size: 1.2em;
    margin-top: 10px;
    color: #555;
    line-height: 1.6;
}

@media (max-width: 768px) {
    .learn-more-section h2 {
        font-size: 2em;
    }

    .faq-question {
        font-size: 1.2em;
    }

    .faq-answer {
        font-size: 1em;
    }
}

@media (max-width: 480px) {
    .learn-more-section {
        padding: 10px;
    }

        .learn-more-section h2 {
            font-size: 1.8em;
        }

    .faq-item {
        padding: 10px;
    }

    .faq-question {
        font-size: 1em;
    }

    .faq-answer {
        font-size: 0.9em;
    }
}
