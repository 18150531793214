.library-form {
    font-family: Comic Sans MS, Arial Rounded MT Bold;
    max-width: 800px;
    margin: 0 auto;
    padding: 30px;
}

    .library-form h2 {
        text-align: center;
        margin-bottom: 20px;
    }
/* Container with rounded corners */
.table-container {
    border-radius: 10px; /* Adjust as needed for more or less rounding */
    overflow: hidden; /* Ensure content stays within rounded corners */
    border: 1px solid #ddd; /* Optional: border around the container */
}

.library-form table {
    width: 100%;
    border-collapse: separate; /* Use 'separate' to allow spacing */
    border-spacing: 1px 10px; /* Add space between rows */
    border-radius: 10px;
    background-color: moccasin;
}

.library-form th,
.library-form td {
    padding: 10px;
    text-align: center;
}

.library-form th {
    background-color: #ff5733;
    color: white;
    border-radius: 10px;
    position: relative;
    top: -8px;
    height: 50px; /* Set a fixed height */
    overflow: hidden; /* Ensure overflow content is hidden */
    white-space: nowrap; /* Prevent text from wrapping */
    text-overflow: ellipsis; /* Add ellipsis if text overflows */
}
.library-form td {
    overflow: auto; /* Add scroll for overflowing content */
    max-height: 100px; /* Set a max height for td to control overflow */
    white-space: pre-wrap; /* Allow text to wrap within the cell */
    word-wrap: break-word; /* Ensure words break within the cell */
}


    .library-form tbody tr:nth-child(even) {
        background-color: #fdebd0;
    }
.library-form tbody tr:nth-child(even) {
    background-color: #ccc;
}

    .library-form tbody tr:hover {
        background-color: #ff5733;
    }


/* Responsive design */
/*@media (max-width: 768px) {
    #libraryforminsidediv {
        padding: 80px;
        height: 60vh;
    }

        .library-form h2 {
            font-size: 1.5em;
            margin-bottom: 15px;
        }

        .library-form table {
            width: 100%;
            border-spacing: 0 5px; 
            font-size: 0.9em; 
        }

        .library-form th,
        .library-form td {
            padding: 8px; 
        }

        .library-form th {
            border-radius: 5px; 
        }

        .library-form tbody tr:hover {
            background-color: #ff8f66; 
        }
}*/

@media (max-width: 480px) {
    #libraryforminsidediv {
        padding: 80px;
        height: 60vh;
    }

        .library-form h2 {
            font-size: 1.2em;
            margin-bottom: 10px;
        }

    .table-container {
        border: none; /* Remove border for mobile */
    }

    .library-form table {
        font-size: 0.8em; /* Further reduce font size */
    }

    .library-form th,
    .library-form td {
        padding: 6px; /* Further reduce padding */
    }

    .library-form th {
        border-radius: 3px; /* Further reduce border radius */
    }

    .library-form tbody tr:hover {
        background-color: #ff8f66; /* Lighter hover color for mobile */
    }

    /* Make table scrollable on smaller screens */
    .table-container {
        overflow-x: auto;
    }

    .library-form table {
        width: 100%;
        min-width: 100px; /* Ensure table does not get too small */
    }
    .library-form th,
    .library-form td {
        white-space: nowrap; /* Prevent text from wrapping */
    }

    .library-form tbody td {
        word-wrap: break-word; /* Ensure content wraps within cells */
        white-space: normal; /* Ensure content wraps within cells */
    }

    .library-form th,
    .library-form td {
        max-width: 50px; /* Adjust as needed */
    }

    .library-form th {
        text-overflow: ellipsis;
        overflow: hidden;
    }

    .library-form td {
        text-overflow: ellipsis;
        overflow: hidden;
    }
}

.pagination {
    margin-top: 20px;
    text-align: center;
}

    .pagination button {
        margin: 0 5px;
        padding: 8px 12px;
        border: none;
        border-radius: 5px;
        background-color: #ff5733;
        color: white;
        cursor: pointer;
        transition: background-color 0.3s ease;
    }

        .pagination button:hover {
            background-color: #ff7f50;
        }








.book {
    transition: opacity 0.4s 0.2s;
    perspective: 250vw;
    width: 80vw; /* Adjust width of the book */
    height: 44vw; /* Adjust height of the book */
    position: relative;
    transform-style: preserve-3d;
    backface-visibility: hidden;
    border-radius: 4px;
    transform: translateX(-50%);
    left: 50%;
}

/* Page styling */
.page {
    display: flex; /* Added display: flex */
    justify-content: center; /* Center content horizontally */
    align-items: center; /* Center content vertically */
    /* 
    display: block;
    width: 100%; Adjust width to fill the whole space of the page */
    height: 100%; /* Adjust height to fill the whole space of the page */
    background-color: #111111;
    margin-bottom: 0.5em;
    background: left top no-repeat;
    background-size: cover;
}

    .page:nth-child(even) {
        clear: both;
    }

    /*book title syle*/
#booktitlestyle {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%; /* Ensure it takes the full height of the parent div */
    font-family: 'Georgia', serif; /* Choose a decorative font */
    font-size: 2.5em; /* Adjust font size as needed */
    color: #392006; /* Deep purple or any rustic color that suits */
    text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3); /* Add some shadow for depth */
    text-align: center;
    padding: 17%; /* Add some padding for spacing */
    line-height: 1.2; /* Increase line height for better readability */
}


/* Content area styling */
.page-content {
    flex: 1;
    padding: 20px; /* Add padding to the content area */

    display: flex;
    align-items: center; /* Center content vertically */
    flex-direction: column; /* Added flex-direction: column */
    justify-content: center; /* Center content vertically */
    font-size: larger;
}

    .page-content > div {
        flex: 1;
        padding-right: 20px; /* Add spacing between text and image */
    }

    .page-content img {
        max-width: 100%; /* Adjust image size */
        height: auto; /* Maintain aspect ratio */
    }





/* Book animation and interaction */
.book .page {
    float: none;
    clear: none;
    margin: 0;
    position: absolute;
    top: 0;
    width: 40vw;
    height: 44vw;
    transform-origin: 0 0;
    transition: transform 1.4s;
    backface-visibility: hidden;
    transform-style: preserve-3d;
    cursor: pointer;
    user-select: none;
    background-color: #f0f0f0;
    max-height: 100%; /* Ensure the content fits within the container */
    overflow-y: auto; /* Add vertical scroll for overflowing content */
    word-wrap: break-word; /* Break long words to prevent overflow */
    white-space: normal; /* Allow text to wrap within the container */
    /*  display: flex;*/
 
}

    .book .page:before {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background: rgba(0, 0, 0, 0);
        transition: background 0.7s;
        z-index: 2;
    }



    .book .page:nth-child(odd) {
        pointer-events: all;
        transform: rotateY(0deg);
        right: 0;
        border-radius: 0 4px 4px 0;
        /* background-image: linear-gradient(to right, rgba(0, 0, 0, .15) 0%, rgba(0, 0, 0, 0) 10%);*/
        background-image: linear-gradient(to right, rgba(215, 180, 90, 1) 0%, rgba(243, 225, 179, 1) 10%);
    }

      .book .page:nth-child(odd):hover {
            transform: rotateY(-15deg);
        }

            .book .page:nth-child(odd):hover:before {
                background: rgba(0, 0, 0, 0.03);
            }

        .book .page:nth-child(odd):before {
            background: rgba(0, 0, 0, 0);
        }

    .book .page:nth-child(even) {
        pointer-events: none;
        transform: rotateY(180deg);
        transform-origin: 100% 0;
        left: 1px;
        border-radius: 4px 0 0 4px;
        border-color: black;
        /*background-image: linear-gradient(to left, rgba(0, 0, 0, .12) 0%, rgba(0, 0, 0, 0) 10%);*/
        background-image: linear-gradient(to left, rgba(215, 180, 90, 1) 0%, rgba(243, 225, 179, 1) 10%);
    }

        .book .page:nth-child(even):before {
            background: rgba(0, 0, 0, 0.2);
        }

      






    .book .page.grabbing {
        transition: none;
    }




    .book .page.flipped:nth-child(odd) {
        pointer-events: none;
        transform: rotateY(-180deg);
    }

        .book .page.flipped:nth-child(odd):before {
            background: rgba(0, 0, 0, 0.2);
        }

    .book .page.flipped:nth-child(even) {
        pointer-events: all;
        transform: rotateY(0deg);
    }

        .book .page.flipped:nth-child(even):hover {
            transform: rotateY(15deg);
        }

            .book .page.flipped:nth-child(even):hover:before {
                background: rgba(0, 0, 0, 0.03);
            }

        .book .page.flipped:nth-child(even):before {
            background: rgba(0, 0, 0, 0);
        }


    


/* Global styles */
*,
* :before,
*:after {
    box-sizing: border-box;
}

html,
body {
    font-family: Comic Sans MS, Arial Rounded MT Bold;
    background: #333;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

html {
    height: 100%;
}

body {
    min-height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2em 0;
    line-height: 1.5em;
}

.page:nth-child(odd) {
    background-position: right top;
}





#librarynavigation-buttons {
    display: flex; /* Use flexbox */
    justify-content: center; /* Align buttons evenly */
    margin-top: 34px;
    margin-bottom: -16px;
}

    #librarynavigation-buttons button {
        width: auto;
        margin-right: 10px; /* Add margin between buttons */
        padding: 15px;
        border: none;
        border-radius: 10px;
        background-color: #ff5733; /* Orange button color */
        color: white;
        font-size: 20px;
        cursor: pointer;
        transition: background-color 0.3s ease;
    }

        #librarynavigation-buttons button:hover {
            background-color: #ff834c; /* Darker orange color on hover */
        }
        /*background for book and pages*/
#bookbackground {
    background-image: url('./tablepic.png');
    background-repeat: no-repeat; /* Ensure the image does not repeat */
    background-position: center; /* Center the image */
    background-size: cover;
    margin-top: 10px;
    margin-bottom: 10px;
}

.frontpage-background {
    background-image: url('./frontbookcover.png') !important;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 800px 730px;
    background-position-x: -110px !important;
    background-position-y: -70px !important;
}
.backpage-background {
    background-image: url('./backbookcover.png') !important;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 780px 740px;
    background-position-x: -140px !important;
    background-position-y: -55px !important;
}

#messageForFlipPhones {
display:none;
}
/* Responsive design for book and navigation buttons */
/* Changes for book and navigation buttons for smaller screens */
/*@media (max-width: 768px) {
    .book {
        height: 60vh;
        width: 185%;
        margin-top: -111px;
        left: 60%;
    }

    #librarynavigation-buttons {
        margin-top: -60px;
        margin-bottom: 10px;
        display: flex; 
        justify-content: center; Align buttons evenly 
    }

        #librarynavigation-buttons button {
            padding: 10px;
            font-size: 16px;
        }
    .page-content {
        font-size: 12px; 
        overflow: auto;
        pointer-events: none;
    }

    .book .page:nth-child(2n+1) {
        right: 35px;
    }

    .page:nth-child(even) {
        left: -45px !important;
    }
    #booktitlestyle {
        font-size: 1.6em;
    }
    .page {
        display: block;
    }

    .book .page {
        width: 47.5vw;
        height: 72vw;
        max-height: 100%; 
        overflow-y: auto; 
        word-wrap: break-word; 
        white-space: normal; 
        padding: 15px !important;
    }

    .page-content {
        padding: 7px;
    }

    .page-content-left {
        white-space: normal; 
        padding: 10px !important;
        font-size: x-small;
    }

    .page-content img {
        margin-top: 30%;
        max-width: 104%;
    }
} */
@media (max-width: 480px) {
    .book {
        width: 104vw; /* Use full width on mobile */
        height: 74vw; /* Adjust height accordingly */
    }

        .book .page:nth-child(2n+1) {
            right: 18px;
        }
    .page:nth-child(even) {
        left: 12px !important;
    }

    #librarynavigation-buttons {
        margin-top: 5px;
        margin-bottom: 5px;
    }

    #booktitlestyle {
        font-size: 1.3em;
    }

    #librarynavigation-buttons button {
        padding: 8px;
        font-size: 14px;
    }
    .page {
    display:block;
    }

    .book .page {
        width: 48.5vw;
        height: 72vw;
        max-height: 100%; /* Ensure the content fits within the container */
        overflow-y: auto; /* Add vertical scroll for overflowing content */
        word-wrap: break-word; /* Break long words to prevent overflow */
        white-space: normal; /* Allow text to wrap within the container */
        padding: 15px !important;
      
    }
    .page-content {
        padding: 7px;
    }
    .page-content-left {
        white-space: normal; /* Allow text to wrap within the container */
        padding: 10px !important;
        font-size: x-small !important;
    }
    .page-content img {
        margin-top: 30%;
        max-width: 104%;
    }
    .frontpage-background {
        background-position-x: -38px !important;
        background-position-y: -30px !important;
        background-size: 295px 365px;
    }
    .backpage-background {
        background-position-x: -54px !important;
        background-position-y: -28px !important;
        background-repeat: no-repeat;
        background-size: 300px 366px;
    }


    #messageForFlipPhones {
        display: block;
        position: fixed;
        left: 50%;
        transform: translateX(-50%);
        background-color: #fff;
        border: 1px solid #ccc;
        border-radius: 5px;
        padding: 10px;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
        z-index: 9999;
        width: 75%;
    }
        #messageForFlipPhones p {
            margin: 0;
            padding-right: 30px; /* To create space for the close button */
        }

    .close-button {
        position: absolute;
        top: 5px;
        right: 5px;
        background: none;
        border: none;
        font-size: 16px;
        cursor: pointer;
        color: #888;
    }
}

.section {
    max-width: 900px;
    margin: 60px auto;
    padding: 90px 30px;
    background-color: #E74C3C;
    text-align: center;
    border-radius: 30px;
}

    .section h2 {
        font-size: 3em;
        margin-bottom: 48px !important;
        margin-top: -40px !important;
        color: moccasin !important;
    }


/* Styles for iPads */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 1) and (orientation: portrait) {
    #libraryforminsidediv {
        padding: 80px;
        height: 66vh;
    }
    .page {
        height: 66vw !important;
        max-height: fit-content !important;
    }

    #messageForFlipPhones {
        display: block;
        position: fixed;
        left: 50%;
        transform: translateX(-50%);
        background-color: #fff;
        border: 1px solid #ccc;
        border-radius: 5px;
        padding: 10px;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
        z-index: 9999;
        width: 75%;
    }

        #messageForFlipPhones p {
            margin: 0;
            padding-right: 30px; /* To create space for the close button */
        }

    .close-button {
        position: absolute;
        top: 5px;
        right: 5px;
        background: none;
        border: none;
        font-size: 16px;
        cursor: pointer;
        color: #888;
    }

    .book {
        width: 93vw !important;
    }
    .page {
        width: 47vw !important;
    }

    .page-content img {
        max-width: 104%;
        margin-top: 25%;
    }
}



#notloggedinadjusterforlibraryform {
    margin: 256px auto !important;
}